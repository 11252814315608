import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import Swal from 'sweetalert2';

import Toast from '../../../components/Toast';

import {
  IAnswerSheet,
  FormValues,
} from '../../../common/interfaces/IAnswerSheet';

import {
  putAnswersSheet,
  getOneAnswersSheet,
} from '../../../services/coursesApi/answersSheetService';

import AnswersContent from '../../../components/AnswersContent';

const AnswerSheetForm: React.FC = () => {
  const { id: answerSheetId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [answerSheet, setAnswerSheet] = useState<IAnswerSheet>();
  const [values, setValues] = useState<FormValues>({ result: 0 });

  const editAnswerSheet = async (data: FormValues) => {
    if (!answerSheetId) {
      return;
    }

    const postData = {
      id: answerSheetId,
      result: data.result,
    };

    await putAnswersSheet(postData.id, postData.result)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Resposta atualizada com sucesso!',
        });
        history.push('/respostas');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message || 'Não foi atualizar a resposta!',
        });
      });
  };

  const getAnswerSheet = useCallback(async (id: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    await getOneAnswersSheet(id)
      .then(response => {
        setValues({ result: response.data.answerSheet.result });
        setAnswerSheet(response.data);
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message || 'Não foi encontrar a resposta!',
        });
      });
  }, []);

  useEffect(() => {
    if (answerSheetId) {
      getAnswerSheet(answerSheetId);
    }
  }, [getAnswerSheet, answerSheetId]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Typography variant="h4" style={{ paddingBottom: '20px' }}>
          Resposta
        </Typography>
        <Formik
          initialValues={values}
          enableReinitialize
          validateOnChange
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);

            if (isEdit) {
              await editAnswerSheet(data);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Curso"
                    disabled
                    value={answerSheet?.course.title || ''}
                    defaultValue={answerSheet?.course.title || ''}
                    placeholder="Curso"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Aluno"
                    value={answerSheet?.student.name || ''}
                    defaultValue={answerSheet?.student.name || ''}
                    disabled
                    placeholder="Aluno"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field name="result">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        label="Nota"
                        disabled={!!answerSheetId && !isEdit}
                        defaultValue={field.value}
                        placeholder="Nota"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Salvar
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1">Respostas</Typography>
                  <Grid container spacing={3} item>
                    {answerSheet?.answerSheet.answers &&
                      answerSheet?.answerSheet.answers.map(row => (
                        <>
                          <Grid item xs={12}>
                            <AnswersContent content={row.text} />
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              max-width="100%"
                              margin="0 1rem 1rem"
                              height="1px"
                              bgcolor="#ccc"
                            />
                          </Grid>
                        </>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default AnswerSheetForm;

/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldArray, FieldProps } from 'formik';
import * as yup from 'yup';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from 'sweetalert2';

import Toast from '../../../components/Toast';

import { IQuiz } from '../../../common/interfaces/IQuiz';
import QuestionType from '../../../common/enum/QuestionType';

import {
  postQuiz,
  getOneQuiz,
  putQuiz,
} from '../../../services/coursesApi/quizService';

interface IEditQuiz extends IQuiz {
  id: string;
}

type ICreateQuiz = Omit<IQuiz, 'id'>;

const QuizForm: React.FC = () => {
  const { id: quizId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [quiz, setQuiz] = useState<IQuiz>({
    title: '',
    passingGrade: 60,
    questions: [
      {
        description: '',
        questionType: QuestionType.MULTICHOICE,
        options: [],
      },
    ],
  });

  const validationSchema = yup.object({
    title: yup.string().required('Título é Obrigatório'),
    passingGrade: yup.number().required('Nota de Aprovação é Obrigatório'),
  });

  const createQuiz = (data: ICreateQuiz) => {
    data.questions.forEach(e => {
      e.questionType =
        e.questionType === QuestionType.SINGLECHOICE
          ? QuestionType.MULTICHOICE
          : e.questionType;
      e.options?.forEach(option => {
        // eslint-disable-next-line no-param-reassign
        option.correct = String(option.correct) === 'true';
      });
    });
    postQuiz(data)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Questionário criado com Sucesso!',
        });
        history.push('/questoes');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível criar o questionário!',
        });
      });
  };

  const editQuiz = (data: IEditQuiz) => {
    data.questions.forEach(e => {
      e.questionType =
        e.questionType === QuestionType.SINGLECHOICE
          ? QuestionType.MULTICHOICE
          : e.questionType;
      e.options?.forEach(option => {
        // eslint-disable-next-line no-param-reassign
        option.correct = String(option.correct) === 'true';
      });
    });
    putQuiz(data)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Questionário editado com Sucesso!',
        });
        history.push('/questoes');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível editar o questionário!',
        });
      });
  };

  const getQuiz = useCallback(async (id: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getOneQuiz(id)
      .then(response => {
        setQuiz(response.data);
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível encontrar o questionário!',
        });
      });
  }, []);

  useEffect(() => {
    if (quizId) {
      getQuiz(quizId);
    }
  }, [getQuiz, quizId]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Typography variant="h4" style={{ paddingBottom: '20px' }}>
          Questionário
        </Typography>
        <Formik
          initialValues={quiz}
          enableReinitialize
          validateOnChange
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);

            if (isEdit && quizId) {
              editQuiz({ ...data, id: quizId });
            } else {
              createQuiz(data);
            }

            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Field name="title">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Título"
                        disabled={!!quizId && !isEdit}
                        placeholder="Título"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Field name="passingGrade">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="number"
                        fullWidth
                        label="Nota de Aprovação"
                        placeholder="Nota de Aprovação"
                        variant="outlined"
                        disabled={!!quizId && !isEdit}
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid container item spacing={1}>
                  <Grid item xs={12} lg={12}>
                    <Typography variant="h6">Questões</Typography>
                  </Grid>
                  <FieldArray name="questions">
                    {arrayHelper => (
                      <>
                        <Grid item xs={12} lg={12}>
                          <Button
                            onClick={() => {
                              arrayHelper.push({
                                description: '',
                                questionType: QuestionType.MULTICHOICE,
                                options: [],
                              });
                            }}
                            variant="outlined"
                            color="primary"
                          >
                            Adicionar Questão
                          </Button>
                        </Grid>

                        {values.questions.map((question, index) => (
                          <Grid
                            container
                            item
                            spacing={1}
                            key={`questions.${index}.description`}
                          >
                            <Grid item xs={12} lg={12}>
                              <Typography variant="h6">
                                {`${'Questão '}${index + 1}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={8} lg={8}>
                              <Field name={`questions.${index}.description`}>
                                {({
                                  field,
                                  meta: { touched, error },
                                }: FieldProps) => (
                                  <TextField
                                    {...field}
                                    type="input"
                                    disabled={!!quizId && !isEdit}
                                    fullWidth
                                    label="Enunciado"
                                    placeholder="Enunciado"
                                    variant="outlined"
                                    helperText={touched && error}
                                    error={touched && !!error}
                                    multiline
                                  />
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={3} lg={3}>
                              <Field name={`questions.${index}.questionType`}>
                                {({
                                  field,
                                  meta: { error, touched },
                                }: FieldProps) => (
                                  <Select
                                    {...field}
                                    variant="outlined"
                                    disabled={!!quizId && !isEdit}
                                    fullWidth
                                    label="Tipo da Questão"
                                    placeholder="Tipo da Questão"
                                    error={touched && !!error}
                                    defaultValue={QuestionType.MULTICHOICE}
                                  >
                                    <MenuItem value={QuestionType.MULTICHOICE}>
                                      Múltipla escolha
                                    </MenuItem>
                                    <MenuItem value={QuestionType.SINGLECHOICE}>
                                      Escolha única
                                    </MenuItem>
                                    <MenuItem value={QuestionType.TEXT}>
                                      Texto
                                    </MenuItem>
                                  </Select>
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={1} lg={1}>
                              <IconButton
                                color="secondary"
                                onClick={() => arrayHelper.remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>

                            {question.questionType !== QuestionType.TEXT && (
                              <Grid container item spacing={1}>
                                <Grid item xs={12} lg={12}>
                                  <Typography variant="subtitle1">
                                    Opções
                                  </Typography>
                                </Grid>

                                <FieldArray name={`questions.${index}.options`}>
                                  {secondArrayHelper => (
                                    <React.Fragment
                                      key={`questions.${index}.options`}
                                    >
                                      <Grid item xs={12} lg={12}>
                                        <Button
                                          onClick={() => {
                                            secondArrayHelper.push({
                                              text: '',
                                              correct: false,
                                            });
                                          }}
                                          variant="outlined"
                                          color="primary"
                                        >
                                          Adicionar Opção
                                        </Button>
                                      </Grid>
                                      <Grid container item spacing={1}>
                                        {values.questions[index].options?.map(
                                          (option, indexOption) => (
                                            <>
                                              <Grid item xs={9} lg={9}>
                                                <Field
                                                  name={`questions.${index}.options.${indexOption}.text`}
                                                >
                                                  {({
                                                    field,
                                                    meta: { error, touched },
                                                  }: FieldProps) => (
                                                    <TextField
                                                      {...field}
                                                      disabled={
                                                        !!quizId && !isEdit
                                                      }
                                                      type="input"
                                                      variant="outlined"
                                                      fullWidth
                                                      label="Texto da Opção"
                                                      placeholder="Texto da Opção"
                                                      helperText={
                                                        touched && error
                                                      }
                                                      error={touched && !!error}
                                                    />
                                                  )}
                                                </Field>
                                              </Grid>
                                              <Grid item xs={2} lg={2}>
                                                <Field
                                                  name={`questions.${index}.options.${indexOption}.correct`}
                                                >
                                                  {({
                                                    field,
                                                    meta: { error, touched },
                                                  }: FieldProps) => (
                                                    <Select
                                                      {...field}
                                                      disabled={
                                                        !!quizId && !isEdit
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                      label="Tipo da Opção"
                                                      placeholder="Tipo da Opção"
                                                      error={touched && !!error}
                                                      defaultValue="false"
                                                      value={String(
                                                        field.value,
                                                      )}
                                                    >
                                                      <MenuItem value="true">
                                                        Correto
                                                      </MenuItem>
                                                      <MenuItem value="false">
                                                        Incorreto
                                                      </MenuItem>
                                                    </Select>
                                                  )}
                                                </Field>
                                              </Grid>
                                              <Grid item xs={1} lg={1}>
                                                <IconButton
                                                  color="secondary"
                                                  onClick={() =>
                                                    secondArrayHelper.remove(
                                                      indexOption,
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Grid>
                                            </>
                                          ),
                                        )}
                                      </Grid>
                                    </React.Fragment>
                                  )}
                                </FieldArray>
                              </Grid>
                            )}
                            <Grid item xs={12} lg={12}>
                              <Box
                                width="100%"
                                margin="2rem 0"
                                height="1px"
                                bgcolor="#ccc"
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default QuizForm;

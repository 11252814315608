import { createGlobalStyle } from 'styled-components';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default createGlobalStyle`
  div.react-select {
    &__menu {
      z-index: 10;
    }
  }

  div.editor {
    border: 1px solid #f1f1f1;
    padding: 5px;
    border-radius: 2px;
    height: 300px;
    overflow: scroll;
  }
`;

import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PaginationItem } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { InsertDriveFile } from '@material-ui/icons';
import StatusOrderTypes from '../../../common/enum/StatusOrderTypes';
import { IOrder } from '../../../common/interfaces/IOrder';
import { IPaginateRequest } from '../../../common/interfaces/IPaginateRequest';
import { formatNumberToBRL } from '../../../common/utils/formatNumber';
import { getOrders } from '../../../services/coursesApi/orderService';

const DATE_FORMAT = 'yyyy-MM-dd';

const OrderList: React.FC = () => {
  const [order, setOrder] = useState<IOrder[]>();

  const [paginate, setPaginate] = useState<{
    page: number;
    totalElements: number;
    totalPages: number;
  }>({
    page: 1,
    totalElements: 0,
    totalPages: 0,
  });
  const [paginateRequest, setPaginateRequest] = useState<IPaginateRequest>({
    page: 1,
  });
  const [startAt, setStartAt] = useState(
    format(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      DATE_FORMAT,
    ),
  );
  const [endAt, setEndAt] = useState(
    format(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      DATE_FORMAT,
    ),
  );
  const [limit, setLimit] = useState(30);

  const orderStatus = {
    [StatusOrderTypes.PENDENT]: 'Pagamento Pendente',
    [StatusOrderTypes.PAID]: 'Pago',
    [StatusOrderTypes.CANCELLED]: 'Cancelado',
    [StatusOrderTypes.REFUNDED]: 'Devolvido',
    [StatusOrderTypes.IN_PROCESS]: 'Processando',
    [StatusOrderTypes.FAILED]: 'Fracassou',
    [StatusOrderTypes.IN_DISPUTE]: 'Em Disputa',
  };

  const listCourses = useCallback(() => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getOrders(paginateRequest)
      .then(response => {
        setOrder(response.data.content);
        const { page, totalElements, totalPages } = response.data;

        setPaginate({
          page,
          totalElements,
          totalPages,
        });
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível listar os pedidos!',
        });
      });
  }, [paginateRequest]);

  const handlePageChange = useCallback((page: number) => {
    setPaginateRequest(t => ({ ...t, page }));
  }, []);

  const onFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    getOrders({ limit, startAt, endAt })
      .then(response => {
        setOrder(response.data.content);
        const { page, totalElements, totalPages } = response.data;

        setPaginate({
          page,
          totalElements,
          totalPages,
        });
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível listar os pedidos!',
        });
      });
  };

  const onExport = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const data = order?.map(o => ({
      'Número do Pedido': o.orderNumber,
      'Status do Pedido': orderStatus[o.status],
      'Data do Pedido': format(new Date(o.createdDate), 'dd/MM/yyyy HH:mm'),
      'Total do Pedido': formatNumberToBRL(o.total),
      Produto: o.cart.items?.[0]?.description,
      'Tipo de certificado': o.cart.items?.[0]?.product.name,
      Nome: o.buyer?.name,
      Email: o.buyer?.email,
      Telefone: o.buyer?.phoneNumber,
    }));
    if (!data) return;
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'output.xlsx');
  };

  useEffect(() => {
    listCourses();
  }, [listCourses]);

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" gutterBottom>
          Pedidos
        </Typography>
      </Box>
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item xs={3}>
          <TextField
            type="date"
            label="Data Inicial"
            value={startAt}
            onChange={e =>
              setStartAt(format(new Date(e.currentTarget.value), DATE_FORMAT))
            }
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            type="date"
            label="Data Final"
            value={endAt}
            onChange={e =>
              setEndAt(format(new Date(e.currentTarget.value), DATE_FORMAT))
            }
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Limite"
            type="number"
            value={limit}
            onChange={e => setLimit(Number(e.currentTarget.value))}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={e => onFilter(e)}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="outlined"
            color="inherit"
            fullWidth
            onClick={e => onExport(e)}
          >
            <InsertDriveFile />
            xls
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Número do Pedido</strong>
              </TableCell>
              <TableCell>
                <strong>Status do Pedido</strong>
              </TableCell>
              <TableCell>
                <strong>Data do Pedido</strong>
              </TableCell>
              <TableCell>
                <strong>Total do Pedido</strong>
              </TableCell>
              <TableCell>
                <strong>Produto</strong>
              </TableCell>
              <TableCell>
                <strong>Nome do Comprador</strong>
              </TableCell>
              <TableCell>
                <strong>Email do Comprador</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order &&
              order.map(row => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link to={`/pedido/${row.id}?edit=true`}>
                      {row.orderNumber}
                    </Link>
                  </TableCell>
                  <TableCell>{orderStatus[row.status]}</TableCell>
                  <TableCell>
                    {format(new Date(row.createdDate), 'dd/MM/yyyy HH:mm')}
                  </TableCell>
                  <TableCell>{formatNumberToBRL(row.total)}</TableCell>
                  <TableCell>{row.cart.items?.[0]?.description}</TableCell>
                  <TableCell>{row.buyer?.name}</TableCell>
                  <TableCell>{row.buyer?.email}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box
          display="flex"
          width="100%"
          marginTop="10px"
          marginBottom="10px"
          justifyContent="center"
        >
          {!!paginate.totalPages && (
            <Pagination
              count={paginate.totalPages}
              variant="outlined"
              color="primary"
              hidePrevButton
              hideNextButton
              size="large"
              onChange={(_, p) => handlePageChange(p)}
              renderItem={item => <PaginationItem {...item} />}
            />
          )}
        </Box>
      </TableContainer>
    </Container>
  );
};

export default OrderList;

import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { useParams, useLocation, useHistory, Link } from 'react-router-dom';

import {
  Button,
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import Swal from 'sweetalert2';

import Toast from '../../../components/Toast';

import { IDelivery } from '../../../common/interfaces/IDelivery';
import StatusDeliveryTypes from '../../../common/enum/StatusDeliveryTypes';
import StatusOrderTypes from '../../../common/enum/StatusOrderTypes';
import UserTypes from '../../../common/enum/UserTypes';

import {
  putDelivery,
  getOneDelivery,
} from '../../../services/coursesApi/deliveryService';

const DeliveryForm: React.FC = () => {
  const { id: deliveryId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [delivery, setDelivery] = useState<IDelivery>({
    id: '',
    status: StatusDeliveryTypes.AWAITING_POST,
    active: true,
    trackingCode: '',
    order: {
      buyer: {
        id: '',
        active: true,
        name: '',
        email: '',
        password: '',
        phoneNumber: '',
        role: UserTypes.STUDENT,
      },
      deliverAddress: {
        id: '',
        active: true,
        zipCode: '',
        country: '',
        state: '',
        city: '',
        district: '',
        street: '',
        number: '',
        complement: '',
        contactName: '',
      },
      cart: {
        items: [
          {
            id: '',
            description: '',
            product: {
              details: '',
              enabled: true,
              name: '',
              price: 0,
              productType: 'CERTIFICATE',
              virtual: false,
            },
            referenceId: '',
          },
        ],
        userId: '',
      },
      createdDate: new Date(),
      id: '',
      orderNumber: 0,
      status: StatusOrderTypes.IN_PROCESS,
      subTotal: 0,
      total: 0,
    },
  });

  const editDelivery = async (data: IDelivery) => {
    if (!deliveryId) {
      return;
    }

    const postData = {
      id: deliveryId,
      status: data.status,
    };

    await putDelivery(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Entrega atualizada com Sucesso!',
        });
        history.push('/entregas');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível atualizar a entrega!',
        });
      });
  };

  const getDelivery = useCallback(async (id: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    await getOneDelivery(id)
      .then(response => {
        setDelivery(response.data);
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível encontrar a entrega',
        });
      });
  }, []);

  useEffect(() => {
    if (deliveryId) {
      getDelivery(deliveryId);
    }
  }, [getDelivery, deliveryId]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Typography variant="h4" style={{ paddingBottom: '20px' }}>
          Entrega
        </Typography>
        <Formik
          initialValues={delivery}
          enableReinitialize
          validateOnChange
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);

            if (isEdit) {
              await editDelivery(data);
            }

            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Field name="status">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <Select
                        {...field}
                        variant="outlined"
                        disabled={!!deliveryId && !isEdit}
                        fullWidth
                        label="Status"
                        placeholder="Status"
                        error={touched && !!error}
                      >
                        <MenuItem value={StatusDeliveryTypes.AWAITING_POST}>
                          Aguardando Postagem
                        </MenuItem>
                        <MenuItem value={StatusDeliveryTypes.POSTED}>
                          Postado
                        </MenuItem>
                        <MenuItem value={StatusDeliveryTypes.DELIVERED}>
                          Concluído
                        </MenuItem>
                        <MenuItem value={StatusDeliveryTypes.RETURNED}>
                          Retornado
                        </MenuItem>
                        <MenuItem value={StatusDeliveryTypes.FAILED}>
                          Extraviado
                        </MenuItem>
                      </Select>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle1">Número do Pedido</Typography>
                  <Field name="order.orderNumber">
                    {({ field }: FieldProps) => (
                      <Link to={`/pedido/${values.order && values?.order.id}`}>
                        {field.value}
                      </Link>
                    )}
                  </Field>
                </Grid>

                {values.order?.buyer && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">Comprador</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="order.buyer.name">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Nome"
                            disabled
                            defaultValue={field.value}
                            placeholder="Nome"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={8}>
                      <Field name="order.buyer.email">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Email"
                            disabled
                            defaultValue={field.value}
                            placeholder="Email"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={4}>
                      <Field name="order.buyer.phoneNumber">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Número de Telefone"
                            disabled
                            defaultValue={field.value}
                            placeholder="Rua"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>
                  </>
                )}

                {values.order && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        Endereço de Entrega
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name="order.deliverAddress.zipCode">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="CEP"
                            disabled
                            defaultValue={field.value}
                            placeholder="CEP"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={8}>
                      <Field name="order.deliverAddress.contactName">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Nome para Contato"
                            disabled
                            defaultValue={field.value}
                            placeholder="Nome para Contato"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={10}>
                      <Field name="order.deliverAddress.street">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Rua"
                            disabled
                            defaultValue={field.value}
                            placeholder="Rua"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={2}>
                      <Field name="order.deliverAddress.number">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Número"
                            disabled
                            defaultValue={field.value}
                            placeholder="Número"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={6}>
                      <Field name="order.deliverAddress.complement">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Complemento"
                            disabled
                            defaultValue={field.value}
                            placeholder="Complemento"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={6}>
                      <Field name="order.deliverAddress.district">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Bairro"
                            disabled
                            defaultValue={field.value}
                            placeholder="Bairro"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={4}>
                      <Field name="order.deliverAddress.city">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Cidade"
                            disabled
                            defaultValue={field.value}
                            placeholder="Cidade"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={4}>
                      <Field name="order.deliverAddress.state">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Estado"
                            disabled
                            defaultValue={field.value}
                            placeholder="Estado"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={4}>
                      <Field name="order.deliverAddress.country">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="País"
                            disabled
                            defaultValue={field.value}
                            placeholder="País"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} lg={12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default DeliveryForm;

import { AxiosResponse } from 'axios';
import api from '../api';

import { IDelivery } from '../../common/interfaces/IDelivery';
import { IPageable } from '../../common/interfaces/IPaginateResult';
import { IPaginateRequest } from '../../common/interfaces/IPaginateRequest';
import StatusDeliveryTypes from '../../common/enum/StatusDeliveryTypes';

export async function getDeliveries(
  data?: IPaginateRequest,
): Promise<AxiosResponse<IPageable<IDelivery>>> {
  return api.get(`/checkout/deliveries/getAll`, {
    params: data,
  });
}

export async function getOneDelivery(
  id: string,
): Promise<AxiosResponse<IDelivery>> {
  return api.get(`/checkout/deliveries/${id}`);
}

export async function putDelivery(delivery: {
  id: string;
  status: StatusDeliveryTypes;
}): Promise<AxiosResponse> {
  return api.put(`/checkout/deliveries/`, delivery);
}

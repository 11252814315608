import axios from 'axios';
import { clearToken, getToken } from './token';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
});

api.defaults.headers.Authorization = getToken();

api.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    if (err.response && err.response.status && err.response.status === 403) {
      clearToken();
      api.defaults.headers = {};
    }

    throw err;
  },
);

export default api;

import {
  CssBaseline,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptLocale from 'date-fns/locale/pt-BR';
import materialTheme from './styles/materialTheme';
import GlobalStyles from './styles/global';

import Routes from './routes';

const App: React.FC = () => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
    <MaterialThemeProvider theme={materialTheme}>
      <ThemeProvider theme={materialTheme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <GlobalStyles />
        <CssBaseline />
      </ThemeProvider>
    </MaterialThemeProvider>
  </MuiPickersUtilsProvider>
);

export default App;

import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { format } from 'date-fns';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import {
  Button,
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
  Select,
  MenuItem,
  Switch,
  InputAdornment,
} from '@material-ui/core';

import { KeyboardDateTimePicker } from '@material-ui/pickers';
import AsyncSelect from 'react-select/async';

import { ICoupon } from '../../../common/interfaces/ICoupon';
import { IProduct } from '../../../common/interfaces/IProduct';
import CouponDiscountTypes from '../../../common/enum/CouponDiscountTypes';

import Toast from '../../../components/Toast';

import {
  postCoupon,
  putCoupon,
  getOneCoupon,
} from '../../../services/coursesApi/couponService';
import { getProducts } from '../../../services/coursesApi/productService';
import { IOption } from '../../../common/interfaces/IOption';

const CouponForm: React.FC = () => {
  const { id: CouponId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [products, setproducts] = useState<IProduct[]>([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [coupon, setCoupon] = useState<ICoupon>({
    code: '',
    discountType: CouponDiscountTypes.PERCENTAGE,
    discountValue: 0,
    enabled: true,
    endDate: new Date().toUTCString(),
    startDate: new Date().toUTCString(),
    maxUses: 0,
    maxUsesPerUser: 0,
    minCartQuantity: 0,
    minCartValue: 0,
    productsOnDiscount: [],
  });

  const convertProductsToOptions = (allproducts: IProduct[]) => {
    return allproducts.map(product => ({
      value: product.id,
      label: product.name,
    }));
  };

  const convertOptionsToProducts = (productsIds: string[]) => {
    return products
      .filter(product => productsIds.includes(product.id || ''))
      .map(product => ({
        value: product.id,
        label: product.name,
      }));
  };

  const findProducts = async () => {
    setProductsLoading(true);
    return getProducts()
      .then(res => {
        setProductsLoading(false);
        setproducts(res.data.content);
        return convertProductsToOptions(res.data.content);
      })
      .catch(() => {
        setProductsLoading(false);
        return [];
      });
  };

  const createCoupon = async (data: ICoupon) => {
    const postData: ICoupon = {
      code: data.code,
      discountType: data.discountType,
      discountValue: data.discountValue,
      enabled: data.enabled,
      endDate: data.endDate
        ? format(new Date(data.endDate), "yyyy-MM-dd'T'HH:mm:00")
        : data.endDate,
      startDate: data.startDate
        ? format(new Date(data.startDate), "yyyy-MM-dd'T'HH:mm:00")
        : data.startDate,
      maxUses: data.maxUses,
      maxUsesPerUser: data.maxUsesPerUser,
      minCartQuantity: data.minCartQuantity,
      minCartValue: data.minCartValue,
      productsOnDiscount: data.productsOnDiscount,
    };

    await postCoupon(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Cupom criado com Sucesso!',
        });
        history.push('/cupons');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message || 'Não foi possível criar o cupom!',
        });
      });
  };

  const editCoupon = async (data: ICoupon) => {
    if (!CouponId) {
      return;
    }

    const postData: ICoupon = {
      id: CouponId,
      code: data.code,
      discountType: data.discountType,
      discountValue: data.discountValue,
      enabled: data.enabled,
      endDate: data.endDate
        ? format(new Date(data.endDate), "yyyy-MM-dd'T'HH:mm:00")
        : data.endDate,
      startDate: data.startDate
        ? format(new Date(data.startDate), "yyyy-MM-dd'T'HH:mm:00")
        : data.startDate,
      maxUses: data.maxUses,
      maxUsesPerUser: data.maxUsesPerUser,
      minCartQuantity: data.minCartQuantity,
      minCartValue: data.minCartValue,
      productsOnDiscount: data.productsOnDiscount,
    };

    await putCoupon(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Cupom atualizado com Sucesso!',
        });
        history.push('/cupons');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível atualizar o cupom!',
        });
      });
  };

  const getCoupon = useCallback(async (id: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    await getOneCoupon(id)
      .then(response => {
        response.data.startDate = response.data.startDate
          ? format(new Date(response.data.startDate), "yyyy-MM-dd'T'HH:mm:00")
          : response.data.startDate;
        response.data.endDate = response.data.endDate
          ? format(new Date(response.data.endDate), "yyyy-MM-dd'T'HH:mm:00")
          : response.data.endDate;
        setCoupon(response.data);
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error?.response?.data?.message || 'Não foi econtrar o cupom!',
        });
      });
  }, []);

  useEffect(() => {
    if (CouponId) {
      getCoupon(CouponId);
    }
  }, [getCoupon, CouponId]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Typography variant="h4" style={{ paddingBottom: '20px' }}>
          Cupom
        </Typography>
        <Formik
          initialValues={coupon}
          enableReinitialize
          validateOnChange
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);

            if (isEdit) {
              await editCoupon(data);
            } else {
              await createCoupon(data);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Field name="code">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Código"
                        disabled={!!CouponId && !isEdit}
                        defaultValue={field.value}
                        placeholder="Código"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="subtitle1">Ativado</Typography>
                  <Field name="enabled">
                    {({ field }: FieldProps) => (
                      <Switch
                        {...field}
                        disabled={!!CouponId && !isEdit}
                        color="primary"
                        checked={field.value}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={8}>
                  <Field name="discountType">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <Select
                        {...field}
                        variant="outlined"
                        disabled={!!CouponId && !isEdit}
                        fullWidth
                        label="Tipo de Desconto"
                        placeholder="Tipo de Desconto"
                        defaultValue={field.value}
                        error={touched && !!error}
                      >
                        <MenuItem value={CouponDiscountTypes.PERCENTAGE}>
                          Percentual
                        </MenuItem>
                        <MenuItem value={CouponDiscountTypes.VALUE}>
                          Valor
                        </MenuItem>
                        <MenuItem
                          value={CouponDiscountTypes.LOWER_VALUE_IN_CART}
                        >
                          Menor Item do Carrinho
                        </MenuItem>
                      </Select>
                    )}
                  </Field>
                </Grid>
                {values.discountType !==
                  CouponDiscountTypes.LOWER_VALUE_IN_CART && (
                  <Grid item xs={4}>
                    <Field name="discountValue">
                      {({ field, meta: { error, touched } }: FieldProps) => (
                        <TextField
                          {...field}
                          fullWidth
                          type="number"
                          label="Desconto"
                          disabled={!!CouponId && !isEdit}
                          defaultValue={field.value}
                          placeholder="Desconto"
                          variant="outlined"
                          helperText={touched && error}
                          error={touched && !!error}
                          InputProps={{
                            startAdornment: values.discountType === 'VALUE' && (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                            value:
                              values.discountType === 'VALUE'
                                ? values.discountValue.toFixed(2)
                                : values.discountValue.toFixed(0),
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                )}

                <Grid item xs={3}>
                  <Field name="maxUses">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        label="Máximo de Uso"
                        disabled={!!CouponId && !isEdit}
                        defaultValue={field.value}
                        placeholder="Máximo de Uso"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={3}>
                  <Field name="maxUsesPerUser">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        label="Máximo de Uso por Usuário"
                        disabled={!!CouponId && !isEdit}
                        defaultValue={field.value}
                        placeholder="Máximo de Uso por Usuário"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={3}>
                  <Field name="minCartValue">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        label="Valor Mínimo do Carrinho"
                        disabled={!!CouponId && !isEdit}
                        defaultValue={field.value}
                        placeholder="Máximo de Uso"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={3}>
                  <Field name="minCartQuantity">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        label="Quantidade Mínima do Carrinho"
                        disabled={!!CouponId && !isEdit}
                        defaultValue={field.value}
                        placeholder="Máximo de Uso"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Typography variant="h6">Produtos</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field name="productsOnDiscount">
                    {({
                      form: { setFieldValue },
                      field: { value },
                    }: FieldProps) => (
                      <AsyncSelect
                        classNamePrefix="react-select"
                        isLoading={productsLoading}
                        cacheOptions
                        defaultOptions
                        loadOptions={findProducts}
                        value={convertOptionsToProducts(value)}
                        isMulti
                        onChange={result => {
                          const converted = result.map(res => res.value);
                          setFieldValue('productsOnDiscount', converted);
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field name="startDate">
                    {({
                      field,
                      meta: { error, touched },
                      form: { setFieldValue },
                    }: FieldProps) => (
                      <KeyboardDateTimePicker
                        {...field}
                        autoOk
                        disabled={!!CouponId && !isEdit}
                        inputVariant="outlined"
                        ampm={false}
                        format="dd/MM/yyyy HH:mm"
                        margin="normal"
                        style={{ width: '100%' }}
                        label="Data de Inicio"
                        defaultValue={field.value}
                        placeholder="Data de Inicio"
                        helperText={touched && error}
                        error={touched && !!error}
                        onChange={(date, _value) => {
                          setFieldValue('startDate', date);
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field name="endDate">
                    {({
                      field,
                      meta: { error, touched },
                      form: { setFieldValue },
                    }: FieldProps) => (
                      <KeyboardDateTimePicker
                        {...field}
                        autoOk
                        disabled={!!CouponId && !isEdit}
                        inputVariant="outlined"
                        ampm={false}
                        format="dd/MM/yyyy HH:mm"
                        margin="normal"
                        style={{ width: '100%' }}
                        label="Data Final"
                        defaultValue={field.value}
                        placeholder="Data Final"
                        helperText={touched && error}
                        error={touched && !!error}
                        onChange={(date, _value) => {
                          setFieldValue('endDate', date);
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default CouponForm;

import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import { EditorState, convertToRaw } from 'draft-js';

export const convertRawToEditor = (raw: string): EditorState => {
  return EditorState.createWithContent(stateFromHTML(raw));
};

export const convertEditorToRaw = (state: EditorState): string => {
  return draftToHtml(convertToRaw(state.getCurrentContent()));
};

import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, parseISO } from 'date-fns';
import { EditorState } from 'draft-js';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import {
  CertificateStatusEnum,
  ICertificate,
} from '../../../common/interfaces/ICertificate';
import {
  EnrollmentStatusEnum,
  IEnrollment,
} from '../../../common/interfaces/IEnrollment';
import { IOption } from '../../../common/interfaces/IOption';
import {
  convertEditorToRaw,
  convertRawToEditor,
} from '../../../common/utils/editorStateUtils';
import Toast from '../../../components/Toast';
import {
  getCourses,
  getOneCourses,
} from '../../../services/coursesApi/coursesService';
import {
  createCertificate,
  getOneEnrollment,
  putEnrollment,
} from '../../../services/coursesApi/enrollmentService';
import {
  getOneStudent,
  getStudents,
} from '../../../services/coursesApi/studentService';
import { Wrapper } from './styles';

type Certificate = Omit<ICertificate, 'id' | 'createdDate'> & {
  createdDate: string;
};
type FormValues = Omit<
  IEnrollment,
  'id' | 'certificate' | 'enrollmentDate' | 'details'
> & {
  certificate: Certificate;
  enrollmentDate: string;
  details: EditorState;
};

const EnrollmentForm: React.FC = () => {
  const { id: enrollmentId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [formValues, setFormValues] = useState<FormValues>({
    courseId: '',
    enrollmentDate: undefined as any,
    status: EnrollmentStatusEnum.PENDENT,
    studentId: '',
    details: EditorState.createEmpty(),
    certificate: {
      enrollmentId: '',
      grade: 0,
      code: '',
      status: CertificateStatusEnum.PENDENT,
      durationSelected: 0,
      createdDate: new Date().toString(),
      alternativeCode: '',
      link: '',
      courseNameOverride: '',
      startDate: '',
    },
  });
  const [studentOptions, setStudentOptions] = useState<IOption[]>([]);
  const [studentsLoading, setStudentsLoading] = useState(false);
  const [courseOptions, setCourseOptions] = useState<IOption[]>([]);
  const [coursesLoading, setCoursesLoading] = useState(false);
  const [hasCertificate, setHasCertificate] = useState(false);
  const [defaultUser, setDefaultUser] = useState<IOption>();
  const [defaultCourse, setDefaultCourse] = useState<IOption>();

  const getDefaults = useCallback(async () => {
    if (formValues.studentId) {
      getOneStudent(formValues.studentId).then(res => {
        setDefaultUser({
          label: `${res.data.name} - ${res.data.email}`,
          value: res.data.id,
        });
      });
    }

    if (formValues.courseId) {
      getOneCourses(formValues.courseId).then(res => {
        setDefaultCourse({
          label: res.data.title,
          value: res.data.id,
        });
      });
    }
  }, [formValues]);

  useEffect(() => {
    getDefaults();
  }, [getDefaults]);

  const findStudents = async (q = '') => {
    setStudentsLoading(true);
    return getStudents({
      query: q,
    })
      .then(response => {
        const { content } = response.data;
        const formatedResult = content.map(c => ({
          label: `${c.name} - ${c.email}`,
          value: c.id,
        }));
        setStudentOptions(formatedResult);
        return formatedResult;
      })
      .catch(() => {
        return [];
      })
      .finally(() => {
        setStudentsLoading(false);
      });
  };

  const findCourses = async (q = '') => {
    setCoursesLoading(true);
    return getCourses({
      query: q,
    })
      .then(response => {
        const { content } = response.data;
        const formatedResult = content.map(c => ({
          label: `${c.title}`,
          value: c.id,
        }));
        setCourseOptions(formatedResult);
        return formatedResult;
      })
      .catch(() => {
        return [];
      })
      .finally(() => {
        setCoursesLoading(false);
      });
  };

  const editEnrollment = async (data: FormValues) => {
    if (!enrollmentId) {
      return;
    }
    await putEnrollment({
      ...data,
      id: enrollmentId,
      enrollmentDate: `${data.enrollmentDate}T00:00:00`,
      certificate: hasCertificate
        ? ({
            ...data.certificate,
            createdDate: `${data.certificate.createdDate}T00:00:00`,
            startDate: data.certificate?.startDate
              ? `${data.certificate.startDate}T00:00:00`
              : undefined,
          } as ICertificate)
        : undefined,
      details: convertEditorToRaw(data.details),
    })
      .then(() => {
        // history.push('/matriculas');
        Toast.fire({
          icon: 'success',
          title: 'Matrícula atualizada com Sucesso!',
        });
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível atualizar a matrícula!',
        });
      });
  };

  const getOptionValue = (id: string, options: IOption[]) => {
    const add: IOption[] = [];

    if (defaultCourse) {
      add.push(defaultCourse);
    }

    if (defaultUser) {
      add.push(defaultUser);
    }

    return [...options, ...add].find(o => o.value === id);
  };

  const getEnrollment = useCallback(async (id: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getOneEnrollment(id)
      .then(response => {
        if (response.data.certificate) {
          setHasCertificate(true);
        }

        setFormValues(actual => ({
          ...response.data,
          enrollmentDate: format(
            new Date(response.data.enrollmentDate),
            'yyyy-MM-dd',
          ),
          certificate: response.data.certificate
            ? {
                ...response.data.certificate,
                createdDate: format(
                  new Date(response.data.certificate.createdDate),
                  'yyyy-MM-dd',
                ),
                startDate: response.data.certificate?.startDate
                  ? format(
                      new Date(response.data.certificate.startDate),
                      'yyyy-MM-dd',
                    )
                  : '',
              }
            : { ...actual.certificate },
          details: convertRawToEditor(response.data.details),
        }));
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível encontra a matrícula!',
        });
      });
  }, []);

  const handleCreateCertificate = async () => {
    if (!enrollmentId) {
      Swal.fire({
        title: 'Não foi possível gerar o certificado!',
        html: 'Por favor tente novamente!',
      });
      return;
    }
    Swal.showLoading();
    createCertificate(enrollmentId)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Certificado gerado com sucesso!',
        });
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível gerar o certificado!',
        });
      });
  };

  useEffect(() => {
    if (enrollmentId) {
      getEnrollment(enrollmentId);
    }
  }, [getEnrollment, enrollmentId]);

  return (
    <Container maxWidth="md">
      <Wrapper>
        <Paper style={{ padding: '10px' }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h4" style={{ paddingBottom: '20px' }}>
                Matrícula
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                {formValues.certificate.link && (
                  <Button
                    variant="contained"
                    color="primary"
                    href={formValues.certificate.link}
                    onClick={() => setHasCertificate(true)}
                  >
                    Baixar Certificado
                  </Button>
                )}
                {hasCertificate && !formValues.certificate?.link && (
                  <Button
                    variant="contained"
                    color="default"
                    onClick={handleCreateCertificate}
                  >
                    Gerar Certificado
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <Formik
            initialValues={formValues}
            enableReinitialize
            validateOnChange
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);

              if (isEdit) {
                await editEnrollment(data);
              }

              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid container item xs={6}>
                    <Grid item xs={12} lg={12}>
                      <Typography variant="h6">Aluno</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Field name="studentId">
                        {({
                          form: { setFieldValue },
                          field: { value },
                        }: FieldProps) => (
                          <AsyncSelect
                            classNamePrefix="react-select"
                            placeholder="Selecione o Aluno"
                            isLoading={studentsLoading}
                            disabled={!!enrollmentId && !isEdit}
                            cacheOptions
                            defaultOptions
                            value={getOptionValue(value, studentOptions || [])}
                            loadOptions={findStudents}
                            onChange={e => setFieldValue('studentId', e?.value)}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6}>
                    <Grid item xs={12} lg={12}>
                      <Typography variant="h6">Curso</Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Field name="courseId">
                        {({
                          form: { setFieldValue },
                          field: { value },
                        }: FieldProps) => (
                          <AsyncSelect
                            classNamePrefix="react-select"
                            placeholder="Selecione o Curso"
                            isLoading={coursesLoading}
                            disabled={!!enrollmentId && !isEdit}
                            cacheOptions
                            defaultOptions
                            value={getOptionValue(value, courseOptions || [])}
                            loadOptions={findCourses}
                            onChange={e => setFieldValue('courseId', e?.value)}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} lg={6}>
                    <Field name="enrollmentDate">
                      {({
                        field,
                        meta: { error, touched },
                        form: { setFieldValue },
                      }: FieldProps) => (
                        <KeyboardDatePicker
                          {...field}
                          autoOk
                          disabled={!!enrollmentId && !isEdit}
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          disableFuture
                          style={{ width: '100%' }}
                          label="Matriculado em"
                          value={field?.value ? parseISO(field.value) : null}
                          defaultValue={
                            field?.value ? parseISO(field.value) : null
                          }
                          placeholder="Matriculado em"
                          helperText={touched && error}
                          error={touched && !!error}
                          onChange={date => {
                            setFieldValue(
                              'enrollmentDate',
                              format(date as Date, 'yyyy-MM-dd'),
                            );
                          }}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={6} lg={6}>
                    <Field name="status">
                      {({ field, meta: { error, touched } }: FieldProps) => (
                        <Select
                          {...field}
                          variant="outlined"
                          disabled={!!enrollmentId && !isEdit}
                          fullWidth
                          label="Status da matrícula"
                          placeholder="Status da matrícula"
                          error={touched && !!error}
                          defaultValue={EnrollmentStatusEnum.PENDENT}
                        >
                          <MenuItem value={EnrollmentStatusEnum.ENROLLED}>
                            Matriculado
                          </MenuItem>
                          <MenuItem value={EnrollmentStatusEnum.BLOCKED}>
                            Bloqueado
                          </MenuItem>
                          <MenuItem value={EnrollmentStatusEnum.PENDENT}>
                            Pendente
                          </MenuItem>
                        </Select>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Detalhes</Typography>
                    <Field name="details">
                      {({
                        field,
                        meta: { error, touched },
                        form: { setFieldValue },
                      }: FieldProps) => (
                        <Editor
                          editorState={field.value}
                          editorClassName="editor"
                          onEditorStateChange={state => {
                            setFieldValue(field.name, state);
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setHasCertificate(true)}
                      disabled={hasCertificate || (!!enrollmentId && !isEdit)}
                    >
                      Adicionar Certificado
                    </Button>
                  </Grid>
                  {hasCertificate && (
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12} lg={12}>
                        <Typography variant="h6">Certificado</Typography>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Field name="certificate.courseNameOverride">
                          {({
                            field,
                            meta: { error, touched },
                          }: FieldProps) => (
                            <TextField
                              {...field}
                              fullWidth
                              defaultValue={field.value}
                              label="Sobreescrever nome do curso (opcional)"
                              disabled={!!enrollmentId && !isEdit}
                              placeholder="Sobreescrever nome do curso"
                              variant="outlined"
                              helperText={
                                (touched && error) ||
                                'Será aplicado apenas para este certificado'
                              }
                              error={touched && !!error}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={2}>
                        <Field name="certificate.durationSelected">
                          {({
                            field,
                            meta: { error, touched },
                          }: FieldProps) => (
                            <TextField
                              {...field}
                              type="number"
                              fullWidth
                              defaultValue={field.value}
                              label="Horas"
                              disabled={!!enrollmentId && !isEdit}
                              placeholder="Horas"
                              variant="outlined"
                              helperText={touched && error}
                              error={touched && !!error}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={4} lg={4}>
                        <Field name="certificate.status">
                          {({
                            field,
                            meta: { error, touched },
                          }: FieldProps) => (
                            <Select
                              {...field}
                              variant="outlined"
                              disabled={!!enrollmentId && !isEdit}
                              fullWidth
                              label="Status do certificado"
                              placeholder="Status  do certificado"
                              error={touched && !!error}
                              defaultValue={CertificateStatusEnum.UNLOCKED}
                            >
                              <MenuItem value={CertificateStatusEnum.UNLOCKED}>
                                Desbloqueado
                              </MenuItem>
                              <MenuItem value={CertificateStatusEnum.LOCKED}>
                                Bloqueado
                              </MenuItem>
                              <MenuItem value={CertificateStatusEnum.PENDENT}>
                                Pagamento pendente
                              </MenuItem>
                            </Select>
                          )}
                        </Field>
                      </Grid>

                      <Grid item xs={3}>
                        <Field name="certificate.startDate">
                          {({
                            field,
                            meta: { error, touched },
                            form: { setFieldValue },
                          }: FieldProps) => (
                            <KeyboardDatePicker
                              {...field}
                              autoOk
                              key={field.value}
                              disabled={!!enrollmentId && !isEdit}
                              inputVariant="outlined"
                              format="dd/MM/yyyy"
                              disableFuture
                              value={
                                field?.value ? parseISO(field.value) : null
                              }
                              defaultValue={
                                field?.value ? parseISO(field.value) : null
                              }
                              style={{ width: '100%' }}
                              label="Data de Início"
                              placeholder="Data de Início"
                              helperText={touched && error}
                              error={touched && !!error}
                              onChange={date => {
                                setFieldValue(
                                  'certificate.startDate',
                                  format(date as Date, 'yyyy-MM-dd'),
                                );
                              }}
                            />
                          )}
                        </Field>
                      </Grid>

                      <Grid item xs={3}>
                        <Field name="certificate.createdDate">
                          {({
                            field,
                            meta: { error, touched },
                            form: { setFieldValue },
                          }: FieldProps) => (
                            <KeyboardDatePicker
                              {...field}
                              autoOk
                              disabled={!!enrollmentId && !isEdit}
                              inputVariant="outlined"
                              format="dd/MM/yyyy"
                              disableFuture
                              style={{ width: '100%' }}
                              label="Data de Conclusão"
                              value={
                                field?.value ? parseISO(field.value) : null
                              }
                              defaultValue={
                                field?.value ? parseISO(field.value) : null
                              }
                              placeholder="Data de Conclusão"
                              helperText={touched && error}
                              error={touched && !!error}
                              onChange={date => {
                                setFieldValue(
                                  'certificate.createdDate',
                                  format(date as Date, 'yyyy-MM-dd'),
                                );
                              }}
                            />
                          )}
                        </Field>
                      </Grid>

                      <Grid item xs={6} lg={6}>
                        <Field name="certificate.grade">
                          {({
                            field,
                            meta: { error, touched },
                          }: FieldProps) => (
                            <TextField
                              {...field}
                              type="number"
                              fullWidth
                              label="Nota"
                              defaultValue={field.value}
                              disabled={!!enrollmentId && !isEdit}
                              placeholder="Nota"
                              variant="outlined"
                              helperText={touched && error}
                              error={touched && !!error}
                            />
                          )}
                        </Field>
                      </Grid>

                      <Grid item xs={6} lg={6}>
                        <Field name="certificate.code">
                          {({
                            field,
                            meta: { error, touched },
                          }: FieldProps) => (
                            <TextField
                              {...field}
                              type="input"
                              fullWidth
                              label="Código do Certificado"
                              disabled={!!enrollmentId && !isEdit}
                              placeholder="Código do Certificado"
                              variant="outlined"
                              helperText={
                                touched && error
                                  ? error
                                  : 'Só altere esse dado caso seja extremamente necessário'
                              }
                              error={touched && !!error}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Field name="certificate.alternativeCode">
                          {({
                            field,
                            meta: { error, touched },
                          }: FieldProps) => (
                            <TextField
                              {...field}
                              type="input"
                              fullWidth
                              label="Código alternativo"
                              disabled
                              placeholder="Código alternativo"
                              variant="outlined"
                              helperText="Este código é gerado automaticamente e não pode ser alterado"
                              error={touched && !!error}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} lg={12}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Wrapper>
    </Container>
  );
};

export default EnrollmentForm;

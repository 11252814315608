import { Box, Button, Container, Grid, TextField } from '@material-ui/core';
import { FieldProps, Formik, Field, Form } from 'formik';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { Alert } from '@material-ui/lab';
import AuthCard from '../../components/AuthCard';
import { login } from '../../services/coursesApi/accessService';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('Email é obrigatório'),
  password: Yup.string().required('Senha é obrigatório'),
});

interface Alert {
  severity: 'warning' | 'success' | 'info' | 'error';
  text: string;
}

const Login: React.FC = () => {
  const history = useHistory();

  const [loginError, setLoginError] = useState<Alert | undefined>();

  const handleLogin = useCallback(
    async ({ email, password }) => {
      await login(email, password)
        .then(() => {
          history.push('/');
        })
        .catch(err => {
          if (err?.response?.status === 401) {
            setLoginError({
              severity: 'warning',
              text: 'Email ou senha inválido',
            });
            return;
          }
          setLoginError({
            severity: 'error',
            text: err?.response?.data?.message || 'Ocorreu um erro inesperado',
          });
        });
    },
    [history],
  );

  return (
    <Container maxWidth="xs">
      <AuthCard>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validateOnChange
          validationSchema={schema}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            await handleLogin(data);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={2} alignItems="center" justify="center">
                <Grid item xs={12}>
                  {loginError && (
                    <Alert
                      severity={loginError.severity}
                      style={{ marginBottom: '1rem' }}
                    >
                      {loginError.text}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Field name="email">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        placeholder="Email"
                        label="Email"
                        disabled={isSubmitting}
                        helperText={touched && error}
                        error={touched && !!error}
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="password">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="password"
                        disabled={isSubmitting}
                        placeholder="Senha"
                        label="Senha"
                        helperText={touched && error}
                        error={touched && !!error}
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" width="100%">
                    <Button variant="contained" color="primary" type="submit">
                      Entrar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </AuthCard>
    </Container>
  );
};

export default Login;

import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useState, useCallback, useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { Box, Button, Paper, Typography, Container } from '@material-ui/core';

import { ICategory } from '../../../common/interfaces/ICourse';
import { getCategories } from '../../../services/coursesApi/categoriesService';

const CategoriesList: React.FC = () => {
  const history = useHistory();
  const [categories, setcategories] = useState<ICategory[]>();

  const listAllCategories = useCallback(() => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getCategories()
      .then(response => {
        setcategories(response.data);
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível Listar as categorias!',
        });
      });
  }, []);

  useEffect(() => {
    listAllCategories();
  }, [listAllCategories]);

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" gutterBottom>
          Categorias
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/categoria')}
        >
          Adicionar nova
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Nome</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories &&
              categories.map(row => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link to={`/categoria/${row.id}?edit=true`}>
                      {row.name}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default CategoriesList;

import { AxiosResponse } from 'axios';
import api from '../api';

import { IQuiz } from '../../common/interfaces/IQuiz';
import { IPageable } from '../../common/interfaces/IPaginateResult';
import { IPaginateRequest } from '../../common/interfaces/IPaginateRequest';

export async function getQuizzes(
  data?: IPaginateRequest,
): Promise<AxiosResponse<IPageable<IQuiz>>> {
  return api.get(`/school/quiz/getAll`, {
    params: data,
  });
}

export async function postQuiz(quiz: IQuiz): Promise<AxiosResponse> {
  return api.post(`school/quiz`, quiz);
}

export async function getOneQuiz(id: string): Promise<AxiosResponse<IQuiz>> {
  return api.get(`/school/quiz/${id}`);
}

export async function putQuiz(quiz: IQuiz): Promise<AxiosResponse> {
  return api.put(`/school/quiz`, quiz);
}

import React from 'react';

import { Container, ContentContainer } from './styles';

interface PropsContent {
  content: string;
}

const Content: React.FC<PropsContent> = ({ content }) => (
  <Container>
    <ContentContainer>
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </ContentContainer>
  </Container>
);
export default Content;

import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  IconButton,
} from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import Swal from 'sweetalert2';
import { format } from 'date-fns';

import Toast from '../../../components/Toast';

import { IOrder } from '../../../common/interfaces/IOrder';
import StatusOrderTypes from '../../../common/enum/StatusOrderTypes';

import {
  putOrder,
  getOneOrder,
} from '../../../services/coursesApi/orderService';
import { formatNumberToBRL } from '../../../common/utils/formatNumber';

const OrderForm: React.FC = () => {
  const { id: orderId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [order, setOrder] = useState<IOrder>({
    id: '',
    status: StatusOrderTypes.PENDENT,
    cart: {
      items: [
        {
          id: '',
          description: '',
          referenceId: '',
          product: {
            id: '',
            details: '',
            name: '',
            price: 0,
            productType: 'CERTIFICATE',
            virtual: true,
            enabled: true,
          },
        },
      ],
      id: '',
      userId: '',
    },
    orderNumber: 0,
    subTotal: 0,
    total: 0,
    buyer: {
      name: '',
      email: '',
      phoneNumber: '',
    },
    createdDate: new Date(),
  });

  const editOrder = async (data: IOrder) => {
    if (!orderId) {
      return;
    }

    const postData = {
      id: orderId,
      status: data.status,
    };

    await putOrder(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Pedidos atualizado com Sucesso!',
        });
        history.push('/pedidos');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível atualizar o pedidos!',
        });
      });
  };

  const getOrder = useCallback(async (id: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    await getOneOrder(id)
      .then(response => {
        setOrder(response.data);
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível encontrar o Pedido!',
        });
      });
  }, []);

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
  }, [getOrder, orderId]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h4" style={{ paddingBottom: '20px' }}>
              Pedido #{order.orderNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              Data do Pedido:{' '}
              <b> {format(new Date(order.createdDate), 'dd/MM/yyyy HH:mm')}</b>
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="subtitle1">Informações do Pedido</Typography>
        <Formik
          initialValues={order}
          enableReinitialize
          validateOnChange
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);

            if (isEdit) {
              await editOrder(data);
            }

            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field name="status">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <Select
                        {...field}
                        variant="outlined"
                        disabled={!!orderId && !isEdit}
                        fullWidth
                        label="Status"
                        placeholder="Status"
                        error={touched && !!error}
                      >
                        <MenuItem value={StatusOrderTypes.PAID}>Pago</MenuItem>
                        <MenuItem value={StatusOrderTypes.PENDENT}>
                          Pendente
                        </MenuItem>
                        <MenuItem value={StatusOrderTypes.CANCELLED}>
                          Cancelado
                        </MenuItem>
                        <MenuItem value={StatusOrderTypes.REFUNDED}>
                          Devolvido
                        </MenuItem>
                        <MenuItem value={StatusOrderTypes.IN_PROCESS}>
                          Processando
                        </MenuItem>
                        <MenuItem value={StatusOrderTypes.FAILED}>
                          Fracassou
                        </MenuItem>
                        <MenuItem value={StatusOrderTypes.IN_DISPUTE}>
                          Em Disputa
                        </MenuItem>
                      </Select>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={4}>
                  <Field name="orderNumber">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Número do Pedido"
                        disabled
                        placeholder="Número do Pedido"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={4}>
                  <Field name="total">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Total"
                        disabled
                        placeholder="Total"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                {values.buyer && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">Comprador</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="buyer.name">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Nome"
                            disabled
                            defaultValue={field.value}
                            placeholder="Nome"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={8}>
                      <Field name="buyer.email">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Email"
                            disabled
                            defaultValue={field.value}
                            placeholder="Email"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={3}>
                      <Field name="buyer.phoneNumber">
                        {({ field, meta: { error, touched } }: FieldProps) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="Número de Telefone"
                            disabled
                            defaultValue={field.value}
                            placeholder="Número de Telefone"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        href={`https://wa.me/${order?.buyer?.phoneNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <WhatsAppIcon />
                      </IconButton>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Typography variant="subtitle1">Certificados</Typography>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Detalhes</TableCell>
                        <TableCell align="center">Preço</TableCell>
                        <TableCell align="center">Virtual</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order?.cart?.items.map(row => (
                        <TableRow key={row.id}>
                          <TableCell align="left">
                            <Typography
                              variant="body1"
                              style={{ marginBottom: '0.5rem' }}
                            >
                              {row.description}
                            </Typography>
                            <Chip label={row.product.name} variant="outlined" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.product.details}
                          </TableCell>
                          <TableCell align="center">
                            {formatNumberToBRL(row.product.price)}
                          </TableCell>
                          <TableCell align="center">
                            {row.product.virtual ? 'Sim' : 'Não'}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell rowSpan={3} />
                        <TableCell colSpan={2}>Subtotal</TableCell>
                        <TableCell align="right">
                          {formatNumberToBRL(order.subTotal)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Desconto</TableCell>
                        <TableCell align="right">
                          {order.cart.coupon?.code || ''}
                        </TableCell>
                        <TableCell align="right">
                          {formatNumberToBRL(order.subTotal - order.total)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>Total</TableCell>
                        <TableCell align="right">
                          {formatNumberToBRL(order.total)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default OrderForm;

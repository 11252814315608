import { AxiosResponse } from 'axios';
import api from '../api';

import { IFaqList } from '../../common/interfaces/IFaq';

export async function getFaq(): Promise<{ data: IFaqList }> {
  return api.get(`/school/faq/`);
}

export async function postFaq(faq: IFaqList): Promise<AxiosResponse> {
  return api.post(`/school/faq/`, faq);
}

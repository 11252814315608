import React from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import AdminLayout from '../pages/_layout/AdminLayout';
import LoginLayout from '../pages/_layout/LoginLayout';
import { getToken } from '../services/token';

export type RouteType = 'admin' | 'auth';
export interface RouteWrapperProps extends RouteProps {
  routeType?: RouteType;
  component: React.ComponentType<any>;
}

const RouteWrapper: React.FC<RouteWrapperProps> = ({
  component: Component,
  routeType = 'admin',
  ...rest
}) => {
  const token = getToken();
  const history = useHistory();

  const LayoutOptions = {
    admin: AdminLayout,
    auth: LoginLayout,
  };

  if (!token && routeType !== 'auth') {
    history.push('/acesso/login');
  }

  const Layout = LayoutOptions[routeType];

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteWrapper;

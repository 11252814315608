import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
  Switch,
} from '@material-ui/core';
import Swal from 'sweetalert2';

import Toast from '../../../components/Toast';

import { IProduct } from '../../../common/interfaces/IProduct';

import {
  putProduct,
  postProduct,
  getOneProduct,
} from '../../../services/coursesApi/productService';

const ProductForm: React.FC = () => {
  const { id: productId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [product, setProduct] = useState<IProduct>({
    name: '',
    details: '',
    price: 0,
    virtual: false,
    productType: 'CERTIFICATE',
    enabled: true,
  });

  const createProduct = async (data: IProduct) => {
    const postData: IProduct = {
      name: data.name,
      details: data.details,
      price: data.price,
      virtual: data.virtual,
      productType: data.productType,
      enabled: data.enabled,
    };

    await postProduct(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Certificado criado com Sucesso!',
        });
        history.push('/produtos');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível criar o certificado!',
        });
      });
  };

  const editProduct = async (data: IProduct) => {
    if (!productId) {
      return;
    }

    const postData: IProduct = {
      id: productId,
      name: data.name,
      details: data.details,
      price: data.price,
      virtual: data.virtual,
      productType: data.productType,
      enabled: data.enabled,
    };

    await putProduct(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Certificado atualizado com Sucesso!',
        });
        history.push('/produtos');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível editar o certificado!',
        });
      });
  };

  const getProduct = useCallback(async (id: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    await getOneProduct(id)
      .then(response => {
        setProduct(response.data);
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi encontrar o certificado!',
        });
      });
  }, []);

  useEffect(() => {
    if (productId) {
      getProduct(productId);
    }
  }, [getProduct, productId]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Typography variant="h4" style={{ paddingBottom: '20px' }}>
          Certificado
        </Typography>
        <Formik
          initialValues={product}
          enableReinitialize
          validateOnChange
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);

            if (isEdit) {
              await editProduct(data);
            } else {
              await createProduct(data);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Field name="name">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Título"
                        disabled={!!productId && !isEdit}
                        placeholder="Nome"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="subtitle1">Virtual</Typography>
                  <Field name="virtual">
                    {({ field }: FieldProps) => (
                      <>
                        <Switch
                          {...field}
                          disabled={!!productId && !isEdit}
                          defaultValue={field.value}
                          color="primary"
                          checked={field.value}
                        />
                      </>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Field name="details">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Detalhes"
                        disabled={!!productId && !isEdit}
                        placeholder="Detalhes"
                        variant="outlined"
                        multiline
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Field name="price">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="number"
                        fullWidth
                        label="Preço"
                        disabled={!!productId && !isEdit}
                        placeholder="Preço"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Ativado</Typography>
                  <Field name="enabled">
                    {({ field }: FieldProps) => (
                      <>
                        <Switch
                          {...field}
                          disabled={!!productId && !isEdit}
                          defaultValue={field.value}
                          color="primary"
                          checked={field.value}
                        />
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default ProductForm;

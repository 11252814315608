import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { Box, Button, Paper, Typography, Container } from '@material-ui/core';
import { PaginationItem } from '@material-ui/lab';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { getBanners } from '../../../services/coursesApi/bannerService';
import { IBannersResponse } from '../../../common/interfaces/IBanners';
import { IPaginateRequest } from '../../../common/interfaces/IPaginateRequest';

const BannerList: React.FC = () => {
  const [banner, setBanner] = useState<IBannersResponse[]>();
  const [paginate, setPaginate] = useState<{
    page: number;
    totalElements: number;
    totalPages: number;
  }>({
    page: 1,
    totalElements: 0,
    totalPages: 0,
  });
  const [paginateRequest, setPaginateRequest] = useState<IPaginateRequest>({
    page: 1,
  });
  const history = useHistory();

  const listBanner = useCallback(async () => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getBanners({
      page: paginateRequest.page,
    })
      .then(response => {
        setBanner(response.data.content);

        const { page, totalElements, totalPages } = response.data;

        setPaginate({
          page,
          totalElements,
          totalPages,
        });
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível listar os banners!',
        });
      });
  }, [paginateRequest.page]);

  const handlePageChange = useCallback((page: number) => {
    setPaginateRequest(t => ({ ...t, page }));
  }, []);

  useEffect(() => {
    listBanner();
  }, [listBanner]);

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" gutterBottom>
          Banners
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/banner')}
        >
          Adicionar novo
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Nome</strong>
              </TableCell>
              <TableCell>
                <strong>Data de Inicio</strong>
              </TableCell>
              <TableCell>
                <strong>Data Final</strong>
              </TableCell>
              <TableCell>
                <strong>Ativado</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {banner &&
              banner.map(row => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link to={`banner/${row.id}?edit=true`}>{row.name}</Link>
                  </TableCell>
                  <TableCell>
                    {format(new Date(row.initialDate), 'dd/MM/yyyy HH:mm')}
                  </TableCell>
                  <TableCell>
                    {format(new Date(row.finalDate), 'dd/MM/yyyy HH:mm')}
                  </TableCell>
                  <TableCell>{row.enabled ? 'Ativo' : 'Desativado'}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box
          display="flex"
          width="100%"
          marginTop="10px"
          marginBottom="10px"
          justifyContent="center"
        >
          {!!paginate.totalPages && (
            <Pagination
              count={paginate.totalPages}
              variant="outlined"
              color="primary"
              hidePrevButton
              hideNextButton
              size="large"
              onChange={(_, p) => handlePageChange(p)}
              renderItem={item => <PaginationItem {...item} />}
            />
          )}
        </Box>
      </TableContainer>
    </Container>
  );
};

export default BannerList;

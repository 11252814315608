import * as Yup from 'yup';
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldArray, FieldProps } from 'formik';
import {
  Button,
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
  IconButton,
  Switch,
  makeStyles,
  createStyles,
  Theme,
  Avatar,
  useTheme,
  Link,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { useHistory, useLocation, useParams } from 'react-router';
import Swal from 'sweetalert2';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Toast from '../../../components/Toast';

import {
  ICourseRespose,
  IAsset,
  ICategory,
  ICourseRequest,
} from '../../../common/interfaces/ICourse';

import {
  postCourses,
  getOneCourses,
  putCourses,
  getCategories,
  deleteCourse,
} from '../../../services/coursesApi/coursesService';
import {
  getQuizzes,
  getOneQuiz,
} from '../../../services/coursesApi/quizService';
import {
  convertEditorToRaw,
  convertRawToEditor,
} from '../../../common/utils/editorStateUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }),
);

interface ICourseValues extends Omit<ICourseRespose, 'assets' | 'imageLink'> {
  image: File | string;
  assets: (IAsset | File)[];
}

interface IOption {
  value: string;
  label: string;
}

type FormValues = Omit<ICourseValues, 'id' | 'description'> & {
  description: EditorState;
};

const formSchema = Yup.object().shape({
  pageDescription: Yup.string()
    .nullable()
    .max(155, 'Máximo de caracteres é 155')
    .min(70, 'Mínimo de caracteres é 70'),
  defaultDuration: Yup.number().min(2, 'Mínimo de 2'),
  quizId: Yup.string().required('Obrigatório selecionar um questionário'),
  image: Yup.mixed().required('Obrigatório selecionar uma imagem'),
});

const CoursesForm: React.FC = () => {
  const classes = useStyles();
  const { id: courseId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [course, setCourse] = useState<FormValues>({
    quizId: '',
    title: '',
    pageTitle: '',
    pageDescription: '',
    slug: '',
    description: EditorState.createEmpty(),
    defaultDuration: 0,
    assets: [],
    image: '',
    contents: [],
    isHighlighted: false,
    categories: [],
    enabled: true,
    sequence: 0,
    videoLink: '',
  });

  const [quizzes, setQuizzes] = useState<IOption[]>();
  const [quizzesLoading, setQuizzesLoading] = useState(false);
  const [quizQuery, setQuizQuery] = useState<IOption>();
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const showLoading = () => {
    Swal.fire({
      title: 'Salvando...',
      html: 'Por favor aguarde, estamos fazendo o upload de arquivos',
    });
    Swal.showLoading();
  };

  const hideLoading = () => {
    Swal.close();
  };

  const handleDelete = () => {
    if (courseId)
      Swal.fire({
        title: 'Tem certeza que deseja apagar o curso?',
        icon: 'warning',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        showCancelButton: true,
      })
        .then(response => {
          if (response.isConfirmed) {
            deleteCourse(courseId)
              .then(() => {
                Toast.fire({
                  icon: 'success',
                  title: 'Curso deletado com sucesso!',
                });
                history.push('/cursos');
              })
              .catch(error => {
                Toast.fire({
                  icon: 'warning',
                  title: 'Este curso não pode ser deletado',
                });
              });
          }
        })
        .catch(error => {
          Swal.update({
            icon: 'error',
            title: 'Oops...',
            text:
              error?.response?.data?.message ||
              'Não foi possível delelatar o curso!',
          });
        });
  };

  const theme = useTheme();

  const getFilesFromForm = (data: FormValues) => {
    const assetFiles: File[] = [];
    const assetLinks: IAsset[] = [];

    const imageLink = typeof data.image === 'string' ? data.image : undefined;
    const imageFile = data.image instanceof File ? data.image : undefined;

    data.assets.forEach(e => {
      if (e instanceof File) {
        assetFiles.push(e);
      } else {
        assetLinks.push(e);
      }
    });

    return {
      assetFiles,
      imageLink,
      assetLinks,
      imageFile,
    };
  };

  const createCourses = async (data: FormValues) => {
    showLoading();
    const { assetFiles, assetLinks, imageFile, imageLink } =
      getFilesFromForm(data);

    const postData: ICourseRequest = {
      categories: data.categories.map(c =>
        c.id === c.name
          ? {
              name: c.name,
            }
          : c,
      ),
      slug: '',
      contents: data.contents,
      description: convertEditorToRaw(data.description),
      pageDescription: data.pageDescription,
      isHighlighted: data.isHighlighted,
      quizId: data.quizId,
      title: data.title,
      pageTitle: data.pageTitle,
      assetFiles,
      assetLinks,
      imageLink,
      imageFile,
      defaultDuration: data.defaultDuration,
      enabled: data.enabled,
      sequence: data.sequence,
      videoLink: data.videoLink?.trim(),
    };

    await postCourses(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Curso criado com Sucesso!',
        });
        history.push('/cursos');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message || 'Não foi possível criar o curso!',
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  const editCourses = async (data: FormValues) => {
    showLoading();
    const { assetFiles, assetLinks, imageFile, imageLink } =
      getFilesFromForm(data);

    if (!courseId) {
      return;
    }

    const postData: ICourseRequest = {
      id: courseId,
      categories: data.categories.map(c =>
        c.id === c.name
          ? {
              name: c.name,
            }
          : c,
      ),
      slug: data.slug,
      contents: data.contents,
      description: convertEditorToRaw(data.description),
      pageDescription: data.pageDescription,
      isHighlighted: data.isHighlighted,
      quizId: data.quizId,
      title: data.title,
      pageTitle: data.pageTitle,
      assetFiles,
      assetLinks,
      imageLink,
      imageFile,
      defaultDuration: data.defaultDuration,
      enabled: data.enabled,
      sequence: data.sequence,
      videoLink: data.videoLink?.trim(),
    };
    await putCourses(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Curso atualizado com Sucesso!',
        });
        history.push('/cursos');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível atualizado o curso!',
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  const getQuizSelected = (id: string, options: IOption[]) => {
    const defaultOptions = quizQuery ? [quizQuery] : [];

    return [...options, ...defaultOptions].find(o => o.value === id);
  };

  const convertCategoriesToOptions = (categories: ICategory[]) => {
    return categories.map(category => ({
      value: category.id || category.name,
      label: category.name,
    }));
  };

  const findCategories = async () => {
    setCategoriesLoading(true);
    return getCategories()
      .then(res => {
        setCategoriesLoading(false);

        return convertCategoriesToOptions(res.data);
      })
      .catch(() => {
        setCategoriesLoading(false);
        return [];
      });
  };

  const getCourse = useCallback(async (id: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getOneCourses(id)
      .then(response => {
        setCourse({
          ...response.data,
          image: response.data.imageLink || '',
          description: convertRawToEditor(response.data.description),
        });
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível encontrar o curso!',
        });
      });
  }, []);

  const getDefaultQuiz = useCallback(async () => {
    if (course) {
      const defaultQuiz = (await getOneQuiz(course.quizId)).data;

      setQuizQuery({
        label: defaultQuiz.title,
        value: defaultQuiz.id as string,
      });
    }
  }, [course]);

  useEffect(() => {
    getDefaultQuiz();
  }, [getDefaultQuiz]);

  const getQuiz = useCallback(async (query?: string) => {
    setQuizzesLoading(true);
    return getQuizzes({
      query,
      limit: 20,
    })
      .then(response => {
        setQuizzesLoading(false);

        const result = response.data.content.map(question => ({
          label: question.title,
          value: question.id as string,
        }));
        setQuizzes(result);
        return result;
      })
      .catch(() => {
        setQuizzesLoading(false);
        return [];
      });
  }, []);

  useEffect(() => {
    if (courseId) {
      getCourse(courseId);
    }
    getQuiz();
  }, [getCourse, courseId, getQuiz]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Typography variant="h4" style={{ paddingBottom: '20px' }}>
          Curso
        </Typography>
        <Formik
          validateOnChange
          enableReinitialize
          initialValues={course}
          validationSchema={formSchema}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);

            if (isEdit) {
              await editCourses(data);
            } else {
              await createCourses(data);
            }

            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field name="title">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Título"
                        disabled={!!courseId && !isEdit}
                        placeholder="Título"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field name="pageTitle">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Título da página"
                        disabled={!!courseId && !isEdit}
                        placeholder="Título da página"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field name="pageDescription">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Descrição da página"
                        disabled={!!courseId && !isEdit}
                        placeholder="Descrição da página"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field name="description">
                    {({
                      field,
                      meta: { error, touched },
                      form: { setFieldValue },
                    }: FieldProps) => (
                      <Editor
                        editorState={field.value}
                        editorClassName="editor"
                        onEditorStateChange={state => {
                          setFieldValue(field.name, state);
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={2}>
                  <Field name="defaultDuration">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="number"
                        fullWidth
                        label="Duração padrão"
                        disabled={!!courseId && !isEdit}
                        placeholder="Duração padrão"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={3}>
                  <Field name="sequence">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="number"
                        fullWidth
                        label="Sequência de exibição na home"
                        disabled={!!courseId && !isEdit}
                        placeholder="Sequência de exibição na home"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={5}>
                  <Field name="videoLink">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Link do vídeo principal"
                        disabled={!!courseId && !isEdit}
                        placeholder="Link do vídeo"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">Mostrar na Home</Typography>
                  <Field name="isHighlighted">
                    {({ field, form: { setFieldValue } }: FieldProps) => (
                      <Switch
                        {...field}
                        disabled={!!courseId && !isEdit}
                        color="primary"
                        checked={field.value}
                        onChange={(_, value) =>
                          setFieldValue('isHighlighted', value)
                        }
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">Questionário</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field name="quizId">
                    {({
                      form: { setFieldValue },
                      field: { value },
                      meta: { error, touched },
                    }: FieldProps) => (
                      <>
                        <AsyncSelect
                          classNamePrefix="react-select"
                          isLoading={quizzesLoading}
                          cacheOptions
                          defaultOptions
                          value={getQuizSelected(value, quizzes || [])}
                          loadOptions={getQuiz}
                          onChange={e => setFieldValue('quizId', e?.value)}
                        />
                        {touched && error && (
                          <Typography color="error">{error}</Typography>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Categorias</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field name="categories">
                    {({
                      form: { setFieldValue },
                      field: { value },
                    }: FieldProps) => (
                      <AsyncCreatableSelect
                        isLoading={categoriesLoading}
                        cacheOptions
                        defaultOptions
                        loadOptions={findCategories}
                        value={convertCategoriesToOptions(value)}
                        isMulti
                        onChange={result => {
                          const converted = result.map(res => ({
                            name: res.label,
                            id: res.value,
                          }));
                          setFieldValue('categories', converted);
                        }}
                        loadingMessage={() => 'Carregando'}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item container spacing={2} alignItems="center">
                  <Field name="image">
                    {({
                      form: { setFieldValue },
                      field: { value },
                      meta: { error, touched },
                    }: FieldProps) => (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Imagem de destaque
                          </Typography>
                        </Grid>
                        {value && typeof value === 'string' && (
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              Imagem atual:
                            </Typography>
                            <Link href={value} target="_blank" rel="noopener">
                              {value}
                            </Link>
                          </Grid>
                        )}

                        <Grid item xs={4} lg={4}>
                          <div className={classes.root}>
                            <label htmlFor="contained-button-file">
                              <input
                                id="contained-button-file"
                                accept="image/*"
                                multiple
                                type="file"
                                className={classes.input}
                                onChange={event => {
                                  if (event.currentTarget.files) {
                                    setFieldValue(
                                      `image`,
                                      event?.currentTarget?.files[0],
                                    );
                                  }
                                }}
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth
                              >
                                Carregar imagem
                              </Button>
                            </label>
                          </div>
                        </Grid>
                        <Grid item xs={8} lg={8}>
                          {value && value instanceof File && (
                            <Typography variant="body1">
                              {value.name}
                            </Typography>
                          )}
                        </Grid>
                        {touched && error && (
                          <Typography color="error">{error}</Typography>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid container item spacing={1}>
                  <FieldArray name="contents">
                    {arrayHelper => (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Conteúdo programático
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            onClick={() => {
                              arrayHelper.push({
                                title: '',
                              });
                            }}
                            variant="outlined"
                            color="primary"
                          >
                            Adicionar Conteúdo
                          </Button>
                        </Grid>

                        {values.contents.map((assest, index) => (
                          <Grid container item spacing={1} alignItems="center">
                            <Grid item xs={12} lg={1}>
                              <Avatar
                                style={{
                                  backgroundColor: theme.palette.primary.main,
                                }}
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>

                            <Field name={`contents.${index}.title`}>
                              {({
                                field,
                                meta: { error, touched },
                              }: FieldProps) => (
                                <>
                                  <Grid item xs={11} lg={10}>
                                    <TextField
                                      {...field}
                                      type="input"
                                      fullWidth
                                      disabled={!!courseId && !isEdit}
                                      placeholder="Conteúdo"
                                      variant="outlined"
                                      helperText={touched && error}
                                      error={touched && !!error}
                                    />
                                  </Grid>
                                </>
                              )}
                            </Field>
                            <Grid item xs={1} lg={1}>
                              <IconButton
                                color="secondary"
                                onClick={() => arrayHelper.remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </Grid>

                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Arquivos</Typography>
                  </Grid>
                  <FieldArray name="assets">
                    {arrayHelper => (
                      <>
                        <Grid item xs={12}>
                          <Button
                            onClick={() => {
                              arrayHelper.push({});
                            }}
                            variant="outlined"
                            color="primary"
                          >
                            Adicionar Arquivos
                          </Button>
                        </Grid>

                        {values.assets.map((assest, index) => (
                          <Grid
                            container
                            item
                            spacing={2}
                            style={{ alignItems: 'center' }}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`assets.${index}`}
                          >
                            <Field name={`assets.${index}`}>
                              {({
                                form: { setFieldValue },
                                field: { value },
                              }: FieldProps) => (
                                <>
                                  <Grid item xs={12}>
                                    <Grid item xs={6} lg={6}>
                                      <Typography variant="subtitle1">
                                        {`${'Arquivo '}${index + 1} `}
                                      </Typography>
                                    </Grid>
                                    {value && 'link' in value && (
                                      <Grid item xs={12}>
                                        <Typography variant="subtitle2">
                                          Arquivo atual:
                                        </Typography>
                                        <Link
                                          href={value.link}
                                          target="_blank"
                                          rel="noopener"
                                        >
                                          {value.name}
                                        </Link>
                                      </Grid>
                                    )}
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                    <div className={classes.root}>
                                      <label htmlFor={`assets.${index}`}>
                                        <input
                                          id={`assets.${index}`}
                                          type="file"
                                          className={classes.input}
                                          onChange={event => {
                                            if (event.currentTarget.files) {
                                              setFieldValue(
                                                `assets.${index}`,
                                                event?.currentTarget?.files[0],
                                              );
                                            }
                                          }}
                                        />
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          component="span"
                                          fullWidth
                                        >
                                          Selecione o Arquivo
                                        </Button>
                                      </label>
                                    </div>
                                    <TextField label="Link para o arquivo" />

                                    <TextField label="Título" />
                                  </Grid>
                                  <Grid item xs={8} lg={8}>
                                    {value && value instanceof File && (
                                      <Typography variant="body1">
                                        {value.name}
                                      </Typography>
                                    )}
                                  </Grid>
                                </>
                              )}
                            </Field>
                            <Grid item xs={1} lg={1}>
                              <IconButton
                                color="secondary"
                                onClick={() => arrayHelper.remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </Grid>
                {/* 
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Ativado</Typography>
                  <Field name="enabled">
                    {({ field }: FieldProps) => (
                      <Switch
                        {...field}
                        disabled={!!courseId && !isEdit}
                        color="primary"
                        checked={field.value}
                      />
                    )}
                  </Field>
                </Grid> */}
                {isEdit && (
                  <Grid item xs={12} lg={6}>
                    <Button
                      disabled={isSubmitting}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      onClick={handleDelete}
                    >
                      Deletar
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} lg={isEdit ? 6 : 12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default CoursesForm;

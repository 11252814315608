import { AxiosResponse } from 'axios';
import api from '../api';

import {
  ICourseAlert,
  ICourseAlertList,
} from '../../common/interfaces/ICourseAlert';
import { IPageable } from '../../common/interfaces/IPaginateResult';
import { IPaginateRequest } from '../../common/interfaces/IPaginateRequest';

export async function getCourseAlert(
  data?: IPaginateRequest,
): Promise<{ data: ICourseAlertList }> {
  return api.get(`/school/courseAlert`, {
    params: data,
  });
}

export async function postCourseAlert(
  courseAlert: ICourseAlert,
): Promise<AxiosResponse> {
  return api.post(`/school/courseAlert`, courseAlert);
}

export async function getOneCourseAlert(
  id: string,
): Promise<AxiosResponse<ICourseAlert>> {
  return api.get(`/school/courseAlert/${id}`);
}

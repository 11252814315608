enum StatusOrderTypes {
  PAID = 'PAID',
  PENDENT = 'PENDENT',
  CANCELLED = 'CANCELLED',
  REFUNDED = 'REFUNDED',
  IN_PROCESS = 'IN_PROCESS',
  FAILED = 'FAILED',
  IN_DISPUTE = 'IN_DISPUTE',
}

export default StatusOrderTypes;

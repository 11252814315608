import React from 'react';
import { Switch } from 'react-router-dom';

import Dashboard from '../pages/Dashboard';
import QuizzesList from '../pages/Quiz/QuizzesList';
import QuizForm from '../pages/Quiz/QuizForm';
import CoursesList from '../pages/Courses/CoursesList';
import CoursesForm from '../pages/Courses/CoursesForm';
import StudentsList from '../pages/Student/StudentsList';
import StudentForm from '../pages/Student/StudentForm';
import EnrollmentList from '../pages/Enrollment/EnrollmentList';
import EnrollmentForm from '../pages/Enrollment/EnrollmentForm';
import ProductList from '../pages/Products/ProductList';
import ProductForm from '../pages/Products/ProductForm';
import OrderList from '../pages/Order/OrderList';
import OrderForm from '../pages/Order/OrderForm';
import DeliveryList from '../pages/Delivery/DeliveryList';
import DeliveryForm from '../pages/Delivery/DeliveryForm';
import CouponList from '../pages/Coupon/CouponList';
import CouponForm from '../pages/Coupon/CouponForm';
import AnswerSheetList from '../pages/AnswerSheet/AnswerSheetList';
import AnswerSheetForm from '../pages/AnswerSheet/AnswerSheetForm';
import BannerList from '../pages/Banner/BannerList';
import BannerForm from '../pages/Banner/BannerForm';
import CourseAlertList from '../pages/CourseAlert/CourseAlertList';
import CourseAlertForm from '../pages/CourseAlert/CourseAlertForm';
import Faq from '../pages/Faq';
import Route from './Route';
import Login from '../pages/Login';
import CategoriesList from '../pages/Categories/CategoriesList';
import CategoryForm from '../pages/Categories/CategoriesForm';

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Dashboard} />
    <Route exact path="/questao" component={QuizForm} />
    <Route exact path="/questao/:id" component={QuizForm} />
    <Route exact path="/questoes" component={QuizzesList} />
    <Route exact path="/curso" component={CoursesForm} />
    <Route exact path="/curso/:id" component={CoursesForm} />
    <Route exact path="/cursos" component={CoursesList} />
    <Route exact path="/aluno/:id" component={StudentForm} />
    <Route exact path="/alunos" component={StudentsList} />
    <Route exact path="/matricula/:id" component={EnrollmentForm} />
    <Route exact path="/matriculas" component={EnrollmentList} />
    <Route exact path="/produtos" component={ProductList} />
    <Route exact path="/produto" component={ProductForm} />
    <Route exact path="/produto/:id" component={ProductForm} />
    <Route exact path="/pedidos" component={OrderList} />
    <Route exact path="/pedido/:id" component={OrderForm} />
    <Route exact path="/entregas" component={DeliveryList} />
    <Route exact path="/entrega/:id" component={DeliveryForm} />
    <Route exact path="/cupons" component={CouponList} />
    <Route exact path="/cupom" component={CouponForm} />
    <Route exact path="/cupom/:id" component={CouponForm} />
    <Route exact path="/respostas" component={AnswerSheetList} />
    <Route exact path="/resposta/:id" component={AnswerSheetForm} />
    <Route exact path="/banners" component={BannerList} />
    <Route exact path="/banner" component={BannerForm} />
    <Route exact path="/banner/:id" component={BannerForm} />
    <Route exact path="/alertacursos" component={CourseAlertList} />
    <Route exact path="/alertacurso" component={CourseAlertForm} />
    <Route exact path="/alertacurso/:id" component={CourseAlertForm} />
    <Route exact path="/faq" component={Faq} />
    <Route exact path="/acesso/login" routeType="auth" component={Login} />
    <Route exact path="/categorias" component={CategoriesList} />
    <Route exact path="/categoria/:id" component={CategoryForm} />
    <Route exact path="/categoria" component={CategoryForm} />
  </Switch>
);

export default Routes;

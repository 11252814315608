import React, { useCallback, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { Box, Button, Paper, Typography, Container } from '@material-ui/core';
import { PaginationItem } from '@material-ui/lab';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import SearchBar from '../../../components/SearchBar';
import { getQuizzes } from '../../../services/coursesApi/quizService';
import { IQuiz } from '../../../common/interfaces/IQuiz';
import { IPaginateRequest } from '../../../common/interfaces/IPaginateRequest';

const QuizzesList: React.FC = () => {
  const [search, setSearch] = useState('');
  const [quizzes, setQuizzes] = useState<IQuiz[]>();
  const [paginate, setPaginate] = useState<{
    page: number;
    totalElements: number;
    totalPages: number;
  }>({
    page: 1,
    totalElements: 0,
    totalPages: 0,
  });
  const [paginateRequest, setPaginateRequest] = useState<IPaginateRequest>({
    page: 1,
  });
  const history = useHistory();

  const handlePageChange = useCallback((page: number) => {
    setPaginateRequest(t => ({ ...t, page }));
  }, []);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [],
  );

  const listAllQuizzes = (pageRequest: number, query?: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getQuizzes({
      page: pageRequest,
      query,
    })
      .then(response => {
        setQuizzes(response.data.content);

        const { page, totalElements, totalPages } = response.data;

        setPaginate({
          page,
          totalElements,
          totalPages,
        });
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível listar os questionários!',
        });
      });
  };

  const listQuizzes = useCallback(async () => {
    listAllQuizzes(paginateRequest.page || 1);
  }, [paginateRequest.page]);

  const handleSearchClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      listAllQuizzes(paginateRequest.page || 1, search || undefined);
    },
    [paginateRequest.page, search],
  );

  useEffect(() => {
    listQuizzes();
  }, [listQuizzes]);

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" gutterBottom>
          Questionários
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/questao')}
        >
          Adicionar novo
        </Button>
      </Box>
      <Box display="flex" justifyContent="end">
        <SearchBar onChange={handleSearchChange} onClick={handleSearchClick} />
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Titulo</strong>
              </TableCell>
              <TableCell>
                <strong>Nota de Aprovação</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quizzes &&
              quizzes.map(row => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link to={`questao/${row.id}?edit=true`}>{row.title}</Link>
                  </TableCell>
                  <TableCell>{row.passingGrade}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box
          display="flex"
          width="100%"
          marginTop="10px"
          marginBottom="10px"
          justifyContent="center"
        >
          {!!paginate.totalPages && (
            <Pagination
              count={paginate.totalPages}
              variant="outlined"
              color="primary"
              hidePrevButton
              hideNextButton
              size="large"
              onChange={(_, p) => handlePageChange(p)}
              renderItem={item => <PaginationItem {...item} />}
            />
          )}
        </Box>
      </TableContainer>
    </Container>
  );
};

export default QuizzesList;

import React, { useState, useCallback, useEffect } from 'react';
import { Formik, Form, Field, FieldArray, FieldProps } from 'formik';
import {
  Button,
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
  IconButton,
  Box,
  Avatar,
  useTheme,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from 'sweetalert2';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import Toast from '../../components/Toast';
import { IFaqList, IFaq } from '../../common/interfaces/IFaq';
import { postFaq, getFaq } from '../../services/coursesApi/faqService';
import {
  convertEditorToRaw,
  convertRawToEditor,
} from '../../common/utils/editorStateUtils';

type FormFaq = Omit<IFaq, 'description'> & {
  description: EditorState;
};

type FormFaqValues = Omit<IFaqList, 'faqs'> & {
  faqs: FormFaq[];
};

const Faq: React.FC = () => {
  const theme = useTheme();

  const [faqState, setFaqState] = useState<FormFaqValues>({
    faqs: [{ title: '', description: EditorState.createEmpty(), sequence: 0 }],
  });

  const createFaq = async (data: FormFaqValues) => {
    data.faqs.forEach((faq, index) => {
      // eslint-disable-next-line no-param-reassign
      faq.sequence = index;
    });

    const postData: IFaqList = {
      faqs: data.faqs.map(faq => ({
        ...faq,
        description: convertEditorToRaw(faq.description),
      })),
    };

    await postFaq(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Faq Atualizado com Sucesso!',
        });
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message || 'Não foi possível Salva o Faq!',
        });
      });
  };

  const getAllFaq = useCallback(async () => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();

    await getFaq()
      .then(response => {
        setFaqState({
          faqs: response.data.faqs.map(element => ({
            ...element,
            description: convertRawToEditor(element.description),
          })),
        });
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error?.response?.data?.message || 'Não foi econtrar o cupom!',
        });
      });
  }, []);

  useEffect(() => {
    getAllFaq();
  }, [getAllFaq]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Typography variant="h4" style={{ paddingBottom: '20px' }}>
          Perguntas e Respostas
        </Typography>
        <Formik
          initialValues={faqState}
          enableReinitialize
          validateOnChange
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            createFaq(data);
            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FieldArray name="faqs">
                    {arrayHelper => (
                      <>
                        <Grid item xs={12}>
                          <Button
                            style={{ marginBottom: '15px' }}
                            onClick={() => {
                              arrayHelper.push({
                                title: '',
                                description: '',
                              });
                            }}
                            variant="outlined"
                            color="primary"
                          >
                            Adicionar Novo
                          </Button>
                        </Grid>

                        {values.faqs.map((assest, index) => (
                          <Grid container item spacing={1} alignItems="center">
                            <Grid item xs={12} lg={1}>
                              <Avatar
                                style={{
                                  backgroundColor: theme.palette.primary.main,
                                }}
                              >
                                {index + 1}
                              </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                              <Grid
                                container
                                item
                                spacing={1}
                                alignItems="center"
                              >
                                <Grid item xs={12}>
                                  <Field name={`faqs.${index}.title`}>
                                    {({
                                      field,
                                      meta: { error, touched },
                                    }: FieldProps) => (
                                      <>
                                        <Grid item xs={12}>
                                          <TextField
                                            {...field}
                                            type="input"
                                            fullWidth
                                            label="Pergunta"
                                            placeholder="Pergunta"
                                            variant="outlined"
                                            helperText={touched && error}
                                            error={touched && !!error}
                                          />
                                        </Grid>
                                      </>
                                    )}
                                  </Field>
                                </Grid>

                                <Field name={`faqs.${index}.description`}>
                                  {({
                                    field,
                                    meta: { error, touched },
                                    form: { setFieldValue },
                                  }: FieldProps) => (
                                    <Editor
                                      editorState={field.value}
                                      editorClassName="editor"
                                      onEditorStateChange={state => {
                                        setFieldValue(field.name, state);
                                      }}
                                    />
                                  )}
                                </Field>
                              </Grid>
                            </Grid>
                            <Grid item xs={1} lg={1}>
                              <IconButton
                                color="secondary"
                                onClick={() => arrayHelper.remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                              <Box
                                width="100%"
                                margin="2rem 0"
                                height="1px"
                                bgcolor="#ccc"
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default Faq;

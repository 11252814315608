import { AxiosResponse } from 'axios';
import api from '../api';

import { IStudent } from '../../common/interfaces/IStudent';
import { IPaginateRequest } from '../../common/interfaces/IPaginateRequest';
import { IPageable } from '../../common/interfaces/IPaginateResult';

export async function getStudents(
  data?: IPaginateRequest,
): Promise<AxiosResponse<IPageable<IStudent>>> {
  return api.get(`/school/student/getAll`, {
    params: data,
  });
}

export async function getOneStudent(
  id: string,
): Promise<AxiosResponse<IStudent>> {
  return api.get(`/school/student/${id}`);
}

export async function putStudent(data: IStudent): Promise<AxiosResponse> {
  return api.put(`/school/student/adminUpdate`, data);
}

import { AxiosResponse } from 'axios';
import api from '../api';

import { IProduct } from '../../common/interfaces/IProduct';
import { IPageable } from '../../common/interfaces/IPaginateResult';
import { IPaginateRequest } from '../../common/interfaces/IPaginateRequest';

export async function getProducts(
  data?: IPaginateRequest,
): Promise<AxiosResponse<IPageable<IProduct>>> {
  return api.get(`/checkout/products/getAll`, {
    params: data,
  });
}

export async function postProduct(product: IProduct): Promise<AxiosResponse> {
  return api.post(`/checkout/products`, product);
}

export async function getOneProduct(
  id: string,
): Promise<AxiosResponse<IProduct>> {
  return api.get(`/checkout/products/${id}`);
}

export async function putProduct(product: IProduct): Promise<AxiosResponse> {
  return api.put(`/checkout/products`, product);
}

export enum CertificateStatusEnum {
  LOCKED = 'LOCKED',
  PENDENT = 'PENDENT',
  UNLOCKED = 'UNLOCKED',
}

export interface ICertificate {
  id: string;
  enrollmentId: string;
  grade: number;
  code: string;
  status: CertificateStatusEnum;
  durationSelected: number;
  createdDate: string;
  alternativeCode?: string;
  link?: string;
  courseNameOverride?: string;
  startDate?: string;
}

export const certificateStatusPtBr = {
  LOCKED: 'Bloqueado',
  PENDENT: 'Pagamento Pendente',
  UNLOCKED: 'Desbloqueado',
};

import { AxiosResponse } from 'axios';
import api from '../api';

import { IOrder } from '../../common/interfaces/IOrder';
import { IPageable } from '../../common/interfaces/IPaginateResult';
import { IPaginateRequest } from '../../common/interfaces/IPaginateRequest';
import StatusOrderTypes from '../../common/enum/StatusOrderTypes';

export async function getOrders(
  data?: IPaginateRequest,
): Promise<AxiosResponse<IPageable<IOrder>>> {
  return api.get(`/checkout/orders/getAll`, {
    params: data,
  });
}

export async function getOneOrder(id: string): Promise<AxiosResponse<IOrder>> {
  return api.get(`/checkout/orders/${id}`);
}

export async function putOrder(Order: {
  id: string;
  status: StatusOrderTypes;
}): Promise<AxiosResponse> {
  return api.put(`/checkout/orders`, Order);
}

import {
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import InputMask, { Props } from 'react-input-mask';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import Toast from '../../../components/Toast';

import UserType from '../../../common/enum/UserTypes';
import {
  IStudent,
  StudentAttendanceStatusEnum,
} from '../../../common/interfaces/IStudent';
import {
  getOneStudent,
  putStudent,
} from '../../../services/coursesApi/studentService';

type FormValues = Omit<IStudent, 'id'>;

const StudentForm: React.FC = () => {
  const { id: studentId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [student, setStudent] = useState<FormValues>({
    name: '',
    email: '',
    phoneNumber: '',
    cpf: '',
    rgUf: '',
    rg: '',
    birthDate: undefined as any,
    password: '',
    role: UserType.STUDENT,
    attendanceStatus: StudentAttendanceStatusEnum.NOT_ATTENDED,
    address: {
      zipCode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      number: '',
      complement: '',
    },
  });

  const isAddressFilled = (addressObject: any) => {
    return Object.keys(addressObject).some(key => {
      if (key !== 'complement' && addressObject[key] === '') {
        return false;
      }
      return true;
    });
  };

  const isAddressComplete = (addressObject: any) => {
    return Object.keys(addressObject).every(key => {
      if (
        key === 'complement' ||
        key === 'reference' ||
        key === 'alias' ||
        key === 'contactName' ||
        addressObject[key] !== ''
      ) {
        return true;
      }
      return false;
    });
  };

  const editStudent = async (data: FormValues) => {
    if (!studentId) {
      return;
    }

    const addressFilled = data.address ? isAddressFilled(data.address) : false;
    if (addressFilled && !isAddressComplete(data.address)) {
      Toast.fire({
        icon: 'warning',
        title: 'Endereço incompleto, por favor verifique e tente novamente',
      });
      return;
    }

    const postData: IStudent = {
      id: studentId,
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      cpf: data.cpf ? String(data.cpf.replace(/[^0-9]/g, '')) : '',
      rgUf: data.rgUf,
      rg: data.rg,
      birthDate: data.birthDate
        ? format(new Date(data.birthDate), 'yyyy-MM-dd')
        : data.birthDate,
      password: data.password,
      role: data.role,
      attendanceStatus: data.attendanceStatus,
      address: addressFilled ? data.address : undefined,
    };

    await putStudent(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Aluno atualizado com Sucesso!',
        });
        history.push('/alunos');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível atualizar o Aluno!',
        });
      });
  };

  const getStudent = useCallback(async (id: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getOneStudent(id)
      .then(response => {
        response.data.birthDate = response.data.birthDate
          ? format(new Date(response.data.birthDate), 'yyyy-MM-dd')
          : response.data.birthDate;
        setStudent(response.data);
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível encontrar o Aluno!',
        });
      });
  }, []);

  useEffect(() => {
    if (studentId) {
      getStudent(studentId);
    }
  }, [getStudent, studentId]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Typography variant="h4" style={{ paddingBottom: '20px' }}>
          Aluno
        </Typography>
        <Formik
          initialValues={student}
          enableReinitialize
          validateOnChange
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);

            if (isEdit) {
              await editStudent(data);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={8} lg={8}>
                  <Field name="name">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Nome"
                        disabled={!!studentId && !isEdit}
                        placeholder="Nome"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={4}>
                  <Field name="attendanceStatus">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <Select
                        {...field}
                        variant="outlined"
                        disabled={!!studentId && !isEdit}
                        fullWidth
                        label="Status"
                        placeholder="Status"
                        defaultValue={field.value}
                        error={touched && !!error}
                      >
                        <MenuItem value={StudentAttendanceStatusEnum.ATTENDED}>
                          Atendido
                        </MenuItem>
                        <MenuItem
                          value={StudentAttendanceStatusEnum.NOT_ATTENDED}
                        >
                          Não atendido
                        </MenuItem>
                      </Select>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <Field name="email">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Email"
                        disabled={!!studentId && !isEdit}
                        placeholder="Email"
                        variant="outlined"
                        multiline
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Field name="phoneNumber">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Número de Telefone"
                        disabled={!!studentId && !isEdit}
                        placeholder="Número de Telefone"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={5}>
                  <Field name="cpf">
                    {({
                      field,
                      meta: { error, touched },
                      form: { values, handleChange, handleBlur },
                    }: FieldProps) => (
                      <InputMask
                        {...field}
                        mask="999.999.999-99"
                        value={values.cpf}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!!studentId && !isEdit}
                      >
                        {(inputProps: Props & TextFieldProps): ReactElement => (
                          <TextField
                            {...inputProps}
                            type="input"
                            fullWidth
                            label="CPF"
                            placeholder="CPF"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </InputMask>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={2}>
                  <Field name="rgUf">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="UF do RG"
                        disabled={!!studentId && !isEdit}
                        placeholder="UF do RG"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={5}>
                  <Field name="rg">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="RG"
                        disabled={!!studentId && !isEdit}
                        placeholder="RG"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Field name="birthDate">
                    {({
                      field,
                      meta: { error, touched },
                      form: { setFieldValue },
                    }: FieldProps) => (
                      <KeyboardDatePicker
                        {...field}
                        autoOk
                        disabled={!!studentId && !isEdit}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        disableFuture
                        style={{ width: '100%' }}
                        label="Data de Nascimento"
                        defaultValue={field.value}
                        placeholder=""
                        helperText={touched && error}
                        error={touched && !!error}
                        onChange={(date, _value) => {
                          setFieldValue('birthDate', date);
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={3}>
                  <Field name="address.zipCode">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <InputMask
                        {...field}
                        mask="99999-999"
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        disabled={!!studentId && !isEdit}
                      >
                        {(inputProps: Props & TextFieldProps): ReactElement => (
                          <TextField
                            {...inputProps}
                            type="input"
                            fullWidth
                            label="CEP"
                            placeholder="CEP"
                            variant="outlined"
                            helperText={touched && error}
                            error={touched && !!error}
                          />
                        )}
                      </InputMask>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={1}>
                  <Field name="address.state">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="UF"
                        disabled={!!studentId && !isEdit}
                        placeholder="UF"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="address.city">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Cidade"
                        disabled={!!studentId && !isEdit}
                        placeholder="Cidade"
                        variant="outlined"
                        defaultValue={field.value}
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="address.district">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Bairro"
                        disabled={!!studentId && !isEdit}
                        placeholder="Bairro"
                        variant="outlined"
                        defaultValue={field.value}
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="address.street">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Rua"
                        disabled={!!studentId && !isEdit}
                        placeholder="Rua"
                        variant="outlined"
                        defaultValue={field.value}
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="address.number">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Número"
                        disabled={!!studentId && !isEdit}
                        placeholder="Número"
                        variant="outlined"
                        defaultValue={field.value}
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="address.complement">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Complemento"
                        disabled={!!studentId && !isEdit}
                        placeholder="Complemento"
                        variant="outlined"
                        defaultValue={field.value}
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} lg={12}>
                  <Field name="password">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="password"
                        fullWidth
                        label="Senha"
                        disabled={!!studentId && !isEdit}
                        placeholder="Senha"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default StudentForm;

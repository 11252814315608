import { AxiosResponse } from 'axios';
import api from '../api';

import {
  ICategory,
  ICourseRequest,
  ICourseRespose,
} from '../../common/interfaces/ICourse';
import { IPaginateRequest } from '../../common/interfaces/IPaginateRequest';
import { IPageable } from '../../common/interfaces/IPaginateResult';

export async function getCourses(
  data?: IPaginateRequest,
): Promise<AxiosResponse<IPageable<ICourseRespose>>> {
  return api.get(`/school/course/getCourses`, {
    params: data,
  });
}

function convertRequestToMultipart(course: ICourseRequest) {
  const formData = new FormData();

  if (course?.id) {
    formData.append('id', course.id);
  }

  if (course?.pageTitle) {
    formData.append('pageTitle', course.pageTitle);
  }

  formData.append('title', course.title);
  formData.append('quizId', course.quizId);
  formData.append('slug', course.slug);
  formData.append('description', course.description);
  formData.append('pageDescription', course?.pageDescription || '');
  formData.append('sequence', JSON.stringify(course.sequence));
  formData.append('isHighlighted', JSON.stringify(course.isHighlighted));
  formData.append('enabled', JSON.stringify(course.enabled));
  formData.append('imageLink', course.imageLink || '');
  formData.append('videoLink', course.videoLink || '');
  formData.append('defaultDuration', JSON.stringify(course.defaultDuration));

  course.assetFiles?.forEach(file => {
    formData.append('assetFiles', file);
  });

  formData.append('assetLinks', JSON.stringify(course.assetLinks || []));
  formData.append('contents', JSON.stringify(course.contents || []));
  formData.append('categories', JSON.stringify(course.categories || []));

  if (course.imageFile) {
    formData.append('imageFile', course.imageFile);
  }

  return formData;
}

export async function postCourses(
  course: ICourseRequest,
): Promise<AxiosResponse> {
  const formData = convertRequestToMultipart(course);

  return api.post(`/school/course`, formData, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
}

export async function deleteCourse(courseId: string): Promise<AxiosResponse> {
  return api.delete(`/school/course/${courseId}`);
}

export async function getOneCourses(
  id: string,
): Promise<AxiosResponse<ICourseRespose>> {
  return api.get(`/school/course/${id}`);
}

export async function putCourses(
  course: ICourseRequest,
): Promise<AxiosResponse> {
  const formData = convertRequestToMultipart(course);
  return api.put(`/school/course`, formData, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
}

export async function getCategories(): Promise<AxiosResponse> {
  return api.get(`/school/course/categories`);
}

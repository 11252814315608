import { AxiosResponse } from 'axios';
import api from '../api';

import { ICoupon } from '../../common/interfaces/ICoupon';
import { IPageable } from '../../common/interfaces/IPaginateResult';
import { IPaginateRequest } from '../../common/interfaces/IPaginateRequest';

export async function getCoupons(
  data?: IPaginateRequest,
): Promise<AxiosResponse<IPageable<ICoupon>>> {
  return api.get(`/checkout/coupon`, {
    params: data,
  });
}

export async function postCoupon(coupon: ICoupon): Promise<AxiosResponse> {
  return api.post(`/checkout/coupon`, coupon);
}

export async function getOneCoupon(
  id: string,
): Promise<AxiosResponse<ICoupon>> {
  return api.get(`/checkout/coupon/${id}`);
}

export async function putCoupon(coupon: ICoupon): Promise<AxiosResponse> {
  return api.put(`/checkout/coupon`, coupon);
}

import { AxiosResponse } from 'axios';
import api from '../api';

import {
  IBannersResponse,
  IBannersRequest,
} from '../../common/interfaces/IBanners';
import { IPaginateRequest } from '../../common/interfaces/IPaginateRequest';
import { IPageable } from '../../common/interfaces/IPaginateResult';

export async function getBanners(
  data?: IPaginateRequest,
): Promise<AxiosResponse<IPageable<IBannersResponse>>> {
  return api.get(`/school/banner`, {
    params: data,
  });
}

function convertRequestToMultipart(banner: IBannersRequest) {
  const formData = new FormData();

  if (banner.id) {
    formData.append('id', banner.id);
  }

  formData.append('finalDate', banner.finalDate);
  formData.append('name', banner.name);
  formData.append('initialDate', banner.initialDate);
  formData.append('enabled', JSON.stringify(banner.enabled));
  formData.append('sequence', JSON.stringify(banner.sequence));
  formData.append('imageLink', banner.imageLink || '');

  if (banner.imageFile) {
    formData.append('imageFile', banner.imageFile);
  }

  return formData;
}

export async function postBanner(
  banner: IBannersRequest,
): Promise<AxiosResponse> {
  const formData = convertRequestToMultipart(banner);

  return api.post(`/school/banner`, formData, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
}

export async function getOneBanner(
  id: string,
): Promise<AxiosResponse<IBannersResponse>> {
  return api.get(`/school/banner/${id}`);
}

export async function putBanner(
  banner: IBannersRequest,
): Promise<AxiosResponse> {
  const formData = convertRequestToMultipart(banner);
  return api.put(`/school/banner/`, formData, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
}

import React, { useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import {
  Button,
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
} from '@material-ui/core';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';

import { useHistory, useLocation, useParams } from 'react-router';
import Swal from 'sweetalert2';

import { ICategory } from '../../../common/interfaces/ICourse';

import {
  convertEditorToRaw,
  convertRawToEditor,
} from '../../../common/utils/editorStateUtils';
import {
  deleteCategory,
  getOneCategory,
  postCategory,
  putCategory,
} from '../../../services/coursesApi/categoriesService';
import Toast from '../../../components/Toast';

type FormValues = Omit<ICategory, 'id' | 'description'> & {
  description: EditorState;
};

const CategoryForm: React.FC = () => {
  const { id: categoryId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [category, setCategory] = useState<FormValues>({
    description: EditorState.createEmpty(),
    name: '',
  });

  const showLoading = () => {
    Swal.fire({
      title: 'Salvando...',
      html: 'Por favor aguarde, estamos fazendo o upload de arquivos',
    });
    Swal.showLoading();
  };

  const hideLoading = () => {
    Swal.close();
  };

  const editCategory = async (data: FormValues) => {
    showLoading();
    try {
      const postData = {
        id: categoryId,
        name: data.name,
        description: convertEditorToRaw(data.description),
      };
      await putCategory(postData);
      Swal.fire({
        title: 'Sucesso!',
        text: 'Categoria editada com sucesso',
        icon: 'success',
      });
      history.push('/categorias');
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Ocorreu um erro ao editar a categoria',
        icon: 'error',
      });
    } finally {
      hideLoading();
    }
  };

  const createCategory = async (data: FormValues) => {
    showLoading();
    try {
      const postData = {
        name: data.name,
        description: convertEditorToRaw(data.description),
      };
      await postCategory(postData);
      Swal.fire({
        title: 'Sucesso!',
        text: 'Categoria criada com sucesso',
        icon: 'success',
      });
      history.push('/categorias');
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Ocorreu um erro ao criar a categoria',
        icon: 'error',
      });
    } finally {
      hideLoading();
    }
  };

  const getCategory = React.useCallback(async (id: string) => {
    Swal.fire({
      title: 'Carregando...',
      html: 'Por favor aguarde, estamos carregando os dados da categoria',
    });
    Swal.showLoading();
    try {
      const response = await getOneCategory(id);
      setCategory({
        name: response.data.name,
        description: response?.data?.description
          ? convertRawToEditor(response.data.description)
          : EditorState.createEmpty(),
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Ocorreu um erro ao carregar os dados da categoria',
        icon: 'error',
      });
    } finally {
      Swal.close();
    }
  }, []);

  const handleDeleteCategory = () => {
    if (categoryId)
      Swal.fire({
        title: 'Tem certeza que deseja apagar a categoria?',
        icon: 'warning',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        showCancelButton: true,
      })
        .then(response => {
          if (response.isConfirmed) {
            deleteCategory(categoryId)
              .then(() => {
                Toast.fire({
                  icon: 'success',
                  title: 'Categoria deletada com sucesso!',
                });
                history.push('/categorias');
              })
              .catch(error => {
                Toast.fire({
                  icon: 'warning',
                  title: 'Esta categoria não pode ser deletada',
                });
              });
          }
        })
        .catch(error => {
          Swal.update({
            icon: 'error',
            title: 'Oops...',
            text:
              error?.response?.data?.message ||
              'Não foi possível delelatar a categoria!',
          });
        });
  };

  React.useEffect(() => {
    if (categoryId) {
      getCategory(categoryId);
    }
  }, [categoryId, getCategory]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Typography variant="h4" style={{ paddingBottom: '20px' }}>
          Categoria
        </Typography>
        <Formik
          initialValues={category}
          enableReinitialize
          validateOnChange
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);

            if (isEdit) {
              await editCategory(data);
            } else {
              await createCategory(data);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field name="name">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Nome"
                        disabled={!!categoryId && !isEdit}
                        placeholder=""
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="description">
                    {({
                      field,
                      meta: { error, touched },
                      form: { setFieldValue },
                    }: FieldProps) => (
                      <Editor
                        editorState={field.value}
                        editorClassName="editor"
                        onEditorStateChange={state => {
                          setFieldValue(field.name, state);
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Salvar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    onClick={handleDeleteCategory}
                  >
                    Deletar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default CategoryForm;

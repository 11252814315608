import api from '../api';
import { storeToken } from '../token';

export const login = async (email: string, password: string) => {
  return api
    .post(`/users/session/`, {
      email,
      password,
    })
    .then(async res => {
      api.defaults.headers.Authorization = `Bearer ${res.data.accessToken}`;

      storeToken(`Bearer ${res.data.accessToken}`);
    });
};

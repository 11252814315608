import { AxiosResponse } from 'axios';

import api from '../api';
import { ICategory } from '../../common/interfaces/ICourse';

export async function getCategories(): Promise<AxiosResponse<ICategory[]>> {
  return api.get(`/school/course/categories`);
}

export async function getOneCategory(
  id: string,
): Promise<AxiosResponse<ICategory>> {
  return api.get(`/school/course/category/${id}`);
}

export async function postCategory(
  category: ICategory,
): Promise<AxiosResponse> {
  return api.post(`/school/course/category`, category);
}

export async function putCategory(category: ICategory): Promise<AxiosResponse> {
  return api.put(`/school/course/category`, category);
}

export async function deleteCategory(id: string): Promise<AxiosResponse> {
  return api.delete(`/school/course/category/${id}`);
}

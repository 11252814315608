import { IStudent } from './IStudent';
import { ICourseRespose } from './ICourse';
import { ICertificate } from './ICertificate';

export interface IEnrollmentReturn {
  enrollment: IEnrollment;
  student: IStudent;
  course: ICourseRespose;
}

export enum EnrollmentStatusEnum {
  BLOCKED = 'BLOCKED',
  PENDENT = 'PENDENT',
  ENROLLED = 'ENROLLED',
}

export interface IEnrollment {
  id: string;
  courseId: string;
  enrollmentDate: string;
  status: EnrollmentStatusEnum;
  studentId: string;
  certificate?: ICertificate;
  details: string;
}

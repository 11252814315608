import UserType from '../enum/UserTypes';
import { IAddress } from './IAddress';

export enum StudentAttendanceStatusEnum {
  NOT_ATTENDED = 'NOT_ATTENDED',
  ATTENDED = 'ATTENDED',
}
export interface IStudent {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  cpf?: string;
  rgUf?: string;
  rg?: string;
  birthDate?: string;
  password?: string;
  role: UserType;
  attendanceStatus: StudentAttendanceStatusEnum;
  address?: IAddress;
}

export const studentAttendanceStatusPtBr = {
  NOT_ATTENDED: 'Não atendido',
  ATTENDED: 'Atendido',
};

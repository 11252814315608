import React, { useCallback, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PaginationItem } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import TableContainer from '@material-ui/core/TableContainer';
import {
  Box,
  Paper,
  Typography,
  Container,
  Button,
  Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';

import { getEnrollments } from '../../../services/coursesApi/enrollmentService';
import { getStudents } from '../../../services/coursesApi/studentService';
import { getCourses } from '../../../services/coursesApi/coursesService';
import { IEnrollmentReturn } from '../../../common/interfaces/IEnrollment';
import { IStudent } from '../../../common/interfaces/IStudent';
import { ICourseRespose } from '../../../common/interfaces/ICourse';
import { IPaginateRequest } from '../../../common/interfaces/IPaginateRequest';
import { certificateStatusPtBr } from '../../../common/interfaces/ICertificate';

const EnrollmentList: React.FC = () => {
  const [studentLoading, setStudentLoading] = useState(false);
  const [students, setStudents] = useState<IStudent[]>();
  const [studentSelected, setStudentSelected] = useState<string>('');
  const [CoursesLoading, setCoursesLoading] = useState(false);
  const [courses, setCourses] = useState<ICourseRespose[]>();
  const [courseSelected, setCourseSelected] = useState<string>('');
  const [enrollment, setEnrollment] = useState<IEnrollmentReturn[]>();

  const [paginate, setPaginate] = useState<{
    page: number;
    totalElements: number;
    totalPages: number;
  }>({
    page: 1,
    totalElements: 0,
    totalPages: 0,
  });
  const [paginateRequest, setPaginateRequest] = useState<IPaginateRequest>({
    page: 1,
  });

  const handlePageChange = useCallback((page: number) => {
    setPaginateRequest(t => ({ ...t, page }));
  }, []);

  const convertStudentsToOptions = (allStudents: IStudent[]) => {
    const options = [
      {
        value: '',
        label: `Todos`,
      },
    ];
    allStudents.forEach(studentValue => {
      options.push({
        value: studentValue.id,
        label: `${studentValue.name} - ${studentValue.email} - ${studentValue.cpf}`,
      });
    });

    return options;
  };

  const findStudents = async (query?: string) => {
    setStudentLoading(true);
    return getStudents({
      query,
    })
      .then(res => {
        setStudentLoading(false);
        setStudents(res.data.content);
        return convertStudentsToOptions(res.data.content);
      })
      .catch(() => {
        setStudentLoading(false);
        return [];
      });
  };

  const convertCoursesToOptions = (allCourses: ICourseRespose[]) => {
    const options = [
      {
        value: '',
        label: `Todos`,
      },
    ];

    allCourses.forEach(coursesValue => {
      options.push({
        value: coursesValue.id,
        label: coursesValue.title,
      });
    });

    return options;
  };

  const findCourses = async () => {
    setCoursesLoading(true);
    return getCourses()
      .then(res => {
        setCoursesLoading(false);
        setCourses(res.data.content);
        return convertCoursesToOptions(res.data.content);
      })
      .catch(() => {
        setCoursesLoading(false);
        return [];
      });
  };

  const getAllEnrollments = (
    pageRequest: number,
    studentId?: string,
    courseId?: string,
  ) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getEnrollments({
      page: pageRequest,
      courseId,
      studentId,
    })
      .then(response => {
        setEnrollment(response.data.content);
        const { page, totalElements, totalPages } = response.data;

        setPaginate({
          page,
          totalElements,
          totalPages,
        });
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível listar as matrículas!',
        });
      });
  };

  const onFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // console.log()

    getAllEnrollments(
      paginateRequest.page || 1,
      studentSelected || undefined,
      courseSelected || undefined,
    );
  };

  const listEnrollment = useCallback(() => {
    getAllEnrollments(paginateRequest.page || 1);
  }, [paginateRequest]);

  useEffect(() => {
    listEnrollment();
  }, [listEnrollment]);

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" gutterBottom>
          Matrículas
        </Typography>
      </Box>
      <Grid container spacing={3} style={{ marginBottom: 10 }}>
        <Grid item xs={5}>
          <AsyncSelect
            isLoading={studentLoading}
            cacheOptions
            defaultOptions
            loadOptions={findStudents}
            onChange={e => setStudentSelected(e?.value || '')}
            placeholder="Buscar por aluno, digite nome, email ou CPF"
          />
        </Grid>
        <Grid item xs={5}>
          <AsyncSelect
            isLoading={CoursesLoading}
            cacheOptions
            defaultOptions
            loadOptions={findCourses}
            onChange={e => setCourseSelected(e?.value || '')}
            placeholder="Buscar por curso"
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={e => onFilter(e)}
          >
            Filtrar
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Estudante</strong>
              </TableCell>
              <TableCell>
                <strong>Curso</strong>
              </TableCell>
              <TableCell>
                <strong>Data de matrícula</strong>
              </TableCell>
              <TableCell>
                <strong>Data de finalização</strong>
              </TableCell>
              <TableCell>
                <strong>Status do certificado</strong>
              </TableCell>
              <TableCell>
                <strong>Nome</strong>
              </TableCell>
              <TableCell>
                <strong>Email</strong>
              </TableCell>
              <TableCell>
                <strong>Telefone</strong>
              </TableCell>
              <TableCell>
                <strong>Nota</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {enrollment &&
              enrollment.map(row => (
                <TableRow key={row.enrollment.id}>
                  <TableCell>
                    <Link to={`/matricula/${row.enrollment.id}?edit=true`}>
                      {row.student.name}
                    </Link>
                  </TableCell>
                  <TableCell>{row.course.title}</TableCell>
                  <TableCell>
                    {format(
                      new Date(row.enrollment.enrollmentDate),
                      'dd/MM/yyyy',
                    )}
                  </TableCell>
                  <TableCell>
                    {row.enrollment.certificate
                      ? format(
                          new Date(row.enrollment.certificate.createdDate),
                          'dd/MM/yyyy',
                        )
                      : ''}
                  </TableCell>
                  <TableCell>
                    {row.enrollment.certificate
                      ? certificateStatusPtBr[row.enrollment.certificate.status]
                      : ''}
                  </TableCell>
                  <TableCell>{row.student.name}</TableCell>
                  <TableCell>{row.student.email}</TableCell>
                  <TableCell>
                    <a
                      href={`https://api.whatsapp.com/send?phone=55${row.student.phoneNumber}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.student.phoneNumber}
                    </a>
                  </TableCell>
                  <TableCell>
                    {row.enrollment.certificate?.grade
                      ? row.enrollment.certificate.grade
                      : 'Não possui Nota'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box
          display="flex"
          width="100%"
          marginTop="10px"
          marginBottom="10px"
          justifyContent="center"
        >
          {!!paginate.totalPages && (
            <Pagination
              count={paginate.totalPages}
              variant="outlined"
              color="primary"
              hidePrevButton
              hideNextButton
              size="large"
              onChange={(_, p) => handlePageChange(p)}
              renderItem={item => <PaginationItem {...item} />}
            />
          )}
        </Box>
      </TableContainer>
    </Container>
  );
};

export default EnrollmentList;

import React, { useCallback, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PaginationItem } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import TableContainer from '@material-ui/core/TableContainer';
import { Box, Paper, Typography, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { getDeliveries } from '../../../services/coursesApi/deliveryService';
import { IDelivery } from '../../../common/interfaces/IDelivery';
import StatusDeliveryTypes from '../../../common/enum/StatusDeliveryTypes';
import { IPaginateRequest } from '../../../common/interfaces/IPaginateRequest';

const DeliveryList: React.FC = () => {
  const [delivery, setDelivery] = useState<IDelivery[]>();
  const [paginate, setPaginate] = useState<{
    page: number;
    totalElements: number;
    totalPages: number;
  }>({
    page: 1,
    totalElements: 0,
    totalPages: 0,
  });
  const [paginateRequest, setPaginateRequest] = useState<IPaginateRequest>({
    page: 1,
  });

  const deliveryStatus = {
    [StatusDeliveryTypes.AWAITING_POST]: 'Aguardando Postagem',
    [StatusDeliveryTypes.DELIVERED]: 'Concluído',
    [StatusDeliveryTypes.POSTED]: 'Postado',
    [StatusDeliveryTypes.RETURNED]: 'Retornado',
    [StatusDeliveryTypes.FAILED]: 'Extraviado',
  };

  const listCourses = useCallback(() => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getDeliveries(paginateRequest)
      .then(response => {
        setDelivery(response.data.content);
        const { page, totalElements, totalPages } = response.data;

        setPaginate({
          page,
          totalElements,
          totalPages,
        });
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error?.response?.data?.message || 'Não foi listar as entregas!',
        });
      });
  }, [paginateRequest]);

  const handlePageChange = useCallback((page: number) => {
    setPaginateRequest(t => ({ ...t, page }));
  }, []);

  useEffect(() => {
    listCourses();
  }, [listCourses]);

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" gutterBottom>
          Entregas
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Número do Pedido</strong>
              </TableCell>
              <TableCell>
                <strong>Status do Pedido</strong>
              </TableCell>
              <TableCell>
                <strong>Nome do Comprador</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {delivery &&
              delivery.map(row => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link to={`/entrega/${row.id}?edit=true`}>
                      {row?.order && row.order.orderNumber}
                    </Link>
                  </TableCell>
                  <TableCell>{deliveryStatus[row.status]}</TableCell>
                  <TableCell>{row.order?.buyer.name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box
          display="flex"
          width="100%"
          marginTop="10px"
          marginBottom="10px"
          justifyContent="center"
        >
          {!!paginate.totalPages && (
            <Pagination
              count={paginate.totalPages}
              variant="outlined"
              color="primary"
              hidePrevButton
              hideNextButton
              size="large"
              onChange={(_, p) => handlePageChange(p)}
              renderItem={item => <PaginationItem {...item} />}
            />
          )}
        </Box>
      </TableContainer>
    </Container>
  );
};

export default DeliveryList;

import { AxiosResponse } from 'axios';
import api from '../api';

import { IAnswerSheet } from '../../common/interfaces/IAnswerSheet';
import { IPageable } from '../../common/interfaces/IPaginateResult';
import { IPaginateRequest } from '../../common/interfaces/IPaginateRequest';

export async function getAnswersSheet(
  data?: IPaginateRequest,
): Promise<AxiosResponse<IPageable<IAnswerSheet>>> {
  return api.get(`/school/answerSheet/`, {
    params: data,
  });
}

export async function getOneAnswersSheet(
  id: string,
): Promise<AxiosResponse<IAnswerSheet>> {
  return api.get(`/school/answerSheet/${id}`);
}

export async function putAnswersSheet(
  id: string,
  result: number,
): Promise<AxiosResponse> {
  return api.put(`/school/answerSheet/`, { id, result });
}

import React, { useCallback, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PaginationItem } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import TableContainer from '@material-ui/core/TableContainer';
import { Box, Paper, Typography, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import SearchBar from '../../../components/SearchBar';
import { getStudents } from '../../../services/coursesApi/studentService';
import {
  IStudent,
  StudentAttendanceStatusEnum,
  studentAttendanceStatusPtBr,
} from '../../../common/interfaces/IStudent';
import { IPaginateRequest } from '../../../common/interfaces/IPaginateRequest';

const StudentList: React.FC = () => {
  const [search, setSearch] = useState('');
  const [student, setStudent] = useState<IStudent[]>();

  const [paginate, setPaginate] = useState<{
    page: number;
    totalElements: number;
    totalPages: number;
  }>({
    page: 1,
    totalElements: 0,
    totalPages: 0,
  });
  const [paginateRequest, setPaginateRequest] = useState<IPaginateRequest>({
    page: 1,
  });

  const handlePageChange = useCallback((page: number) => {
    setPaginateRequest(t => ({ ...t, page }));
  }, []);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [],
  );

  const listAllStudent = (pageRequest: number, query?: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getStudents({ page: pageRequest, query })
      .then(response => {
        setStudent(response.data.content);
        const { page, totalElements, totalPages } = response.data;

        setPaginate({
          page,
          totalElements,
          totalPages,
        });
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível listar os Aunos!',
        });
      });
  };

  const listStudent = useCallback(() => {
    listAllStudent(paginateRequest.page || 1);
  }, [paginateRequest]);

  const handleSearchClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      listAllStudent(paginateRequest.page || 1, search || undefined);
    },
    [paginateRequest.page, search],
  );

  useEffect(() => {
    listStudent();
  }, [listStudent]);

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" gutterBottom>
          Alunos
        </Typography>
      </Box>
      <Box display="flex" justifyContent="end">
        <SearchBar
          placeholder="Procurar por nome, CPF ou email"
          onChange={handleSearchChange}
          onClick={handleSearchClick}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Matrícula</strong>
              </TableCell>
              <TableCell>
                <strong>Nome</strong>
              </TableCell>
              <TableCell>
                <strong>Telefone</strong>
              </TableCell>
              <TableCell>
                <strong>Email</strong>
              </TableCell>
              <TableCell>
                <strong>CPF</strong>
              </TableCell>
              <TableCell>
                <strong>Status</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {student &&
              student.map(row => (
                <TableRow
                  key={row.id}
                  style={{
                    backgroundColor:
                      row.attendanceStatus ===
                      StudentAttendanceStatusEnum.ATTENDED
                        ? '#B9D770'
                        : undefined,
                  }}
                >
                  <TableCell>
                    <Link to={`/aluno/${row.id}?edit=true`}>{row.id}</Link>
                  </TableCell>
                  <TableCell>
                    <Link to={`/aluno/${row.id}?edit=true`}>{row.name}</Link>
                  </TableCell>
                  <TableCell>
                    <a
                      href={`https://api.whatsapp.com/send?phone=55${row.phoneNumber}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.phoneNumber}
                    </a>
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.cpf}</TableCell>
                  <TableCell>
                    {studentAttendanceStatusPtBr[row.attendanceStatus]}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box
          display="flex"
          width="100%"
          marginTop="10px"
          marginBottom="10px"
          justifyContent="center"
        >
          {!!paginate.totalPages && (
            <Pagination
              count={paginate.totalPages}
              variant="outlined"
              color="primary"
              hidePrevButton
              hideNextButton
              size="large"
              onChange={(_, p) => handlePageChange(p)}
              renderItem={item => <PaginationItem {...item} />}
            />
          )}
        </Box>
      </TableContainer>
    </Container>
  );
};

export default StudentList;

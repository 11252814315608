import { AxiosResponse } from 'axios';
import api from '../api';

import {
  IEnrollment,
  IEnrollmentReturn,
} from '../../common/interfaces/IEnrollment';
import { IRequestEnrollment } from '../../common/interfaces/IPaginateRequest';
import { IPageable } from '../../common/interfaces/IPaginateResult';

export async function getEnrollments(
  data?: IRequestEnrollment,
): Promise<AxiosResponse<IPageable<IEnrollmentReturn>>> {
  return api.get(`/school/enrollment/adminGetAll`, {
    params: data,
  });
}

export async function getOneEnrollment(
  id: string,
): Promise<AxiosResponse<IEnrollment>> {
  return api.get(`/school/enrollment/${id}`);
}

export async function putEnrollment(data: IEnrollment): Promise<AxiosResponse> {
  return api.put(`/school/enrollment`, data);
}

export async function createCertificate(id: string): Promise<AxiosResponse> {
  return api.post('/school/course/createCertificate', {
    enrollmentId: id,
  });
}

import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { format } from 'date-fns';
import {
  Button,
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
  Switch,
  makeStyles,
  createStyles,
  Theme,
  Link,
} from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useHistory, useLocation, useParams } from 'react-router';
import Swal from 'sweetalert2';

import Toast from '../../../components/Toast';

import {
  IBannersResponse,
  IBannersRequest,
} from '../../../common/interfaces/IBanners';

import {
  postBanner,
  getOneBanner,
  putBanner,
} from '../../../services/coursesApi/bannerService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }),
);

interface ICourseValues extends Omit<IBannersResponse, 'imageLink'> {
  image: File | string;
}

const BannerForm: React.FC = () => {
  const classes = useStyles();
  const { id: bannerId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [banner, setBanner] = useState<ICourseValues>({
    name: '',
    initialDate: new Date().toUTCString(),
    finalDate: new Date().toUTCString(),
    enabled: false,
    image: '',
    sequence: 0,
  });

  const createBanner = async (data: ICourseValues) => {
    const imageLink = typeof data.image === 'string' ? data.image : undefined;
    const imageFile = data.image instanceof File ? data.image : undefined;

    const postData: IBannersRequest = {
      name: data.name,
      initialDate: data.initialDate
        ? format(new Date(data.initialDate), "yyyy-MM-dd'T'HH:mm:00")
        : data.initialDate,
      finalDate: data.finalDate
        ? format(new Date(data.finalDate), "yyyy-MM-dd'T'HH:mm:00")
        : data.finalDate,
      enabled: data.enabled,
      imageLink,
      imageFile,
      sequence: data.sequence,
    };

    await postBanner(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Curso criado com Sucesso!',
        });
        history.push('/banners');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível criar o banner!',
        });
      });
  };

  const editBanner = async (data: ICourseValues) => {
    const imageLink = typeof data.image === 'string' ? data.image : undefined;
    const imageFile = data.image instanceof File ? data.image : undefined;

    if (!bannerId) {
      return;
    }

    const postData: IBannersRequest = {
      id: bannerId,
      name: data.name,
      initialDate: data.initialDate
        ? format(new Date(data.initialDate), "yyyy-MM-dd'T'HH:mm:00")
        : data.initialDate,
      finalDate: data.finalDate
        ? format(new Date(data.finalDate), "yyyy-MM-dd'T'HH:mm:00")
        : data.finalDate,
      enabled: data.enabled,
      imageLink,
      imageFile,
      sequence: data.sequence,
    };

    await putBanner(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Banner atualizado com Sucesso!',
        });
        history.push('/banners');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível atualizado o banner!',
        });
      });
  };

  const getCourse = useCallback(async (id: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    getOneBanner(id)
      .then(response => {
        setBanner({ ...response.data, image: response.data.imageLink || '' });
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível encontrar o banner!',
        });
      });
  }, []);

  useEffect(() => {
    if (bannerId) {
      getCourse(bannerId);
    }
  }, [getCourse, bannerId]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Typography variant="h4" style={{ paddingBottom: '20px' }}>
          Banner
        </Typography>
        <Formik
          initialValues={banner}
          enableReinitialize
          validateOnChange
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);

            if (isEdit) {
              await editBanner(data);
            } else {
              await createBanner(data);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Field name="name">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Título"
                        disabled={!!bannerId && !isEdit}
                        placeholder="Título"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={2}>
                  <Field name="sequence">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="number"
                        fullWidth
                        label="Sequêcia de exibição"
                        disabled={!!bannerId && !isEdit}
                        placeholder="Sequêcia de exibição"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">Ativado</Typography>
                  <Field name="enabled">
                    {({ field }: FieldProps) => (
                      <Switch
                        {...field}
                        disabled={!!bannerId && !isEdit}
                        color="primary"
                        checked={field.value}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field name="initialDate">
                    {({
                      field,
                      meta: { error, touched },
                      form: { setFieldValue },
                    }: FieldProps) => (
                      <KeyboardDateTimePicker
                        {...field}
                        autoOk
                        disabled={!!bannerId && !isEdit}
                        inputVariant="outlined"
                        ampm={false}
                        format="dd/MM/yyyy HH:mm"
                        margin="normal"
                        style={{ width: '100%' }}
                        label="Data de Inicio"
                        defaultValue={field.value}
                        placeholder="Data de Inicio"
                        helperText={touched && error}
                        error={touched && !!error}
                        onChange={(date, _value) => {
                          setFieldValue('initialDate', date);
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field name="finalDate">
                    {({
                      field,
                      meta: { error, touched },
                      form: { setFieldValue },
                    }: FieldProps) => (
                      <KeyboardDateTimePicker
                        {...field}
                        autoOk
                        disabled={!!bannerId && !isEdit}
                        inputVariant="outlined"
                        ampm={false}
                        format="dd/MM/yyyy HH:mm"
                        margin="normal"
                        style={{ width: '100%' }}
                        label="Data Final"
                        defaultValue={field.value}
                        placeholder="Data Final"
                        helperText={touched && error}
                        error={touched && !!error}
                        onChange={(date, _value) => {
                          setFieldValue('finalDate', date);
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item container spacing={2} alignItems="center">
                  <Field name="image">
                    {({
                      form: { setFieldValue },
                      field: { value },
                    }: FieldProps) => (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Imagem de destaque
                          </Typography>
                        </Grid>
                        {value && typeof value === 'string' && (
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              Imagem atual:
                            </Typography>
                            <Link href={value} target="_blank" rel="noopener">
                              {value}
                            </Link>
                          </Grid>
                        )}

                        <Grid item xs={4} lg={4}>
                          <div className={classes.root}>
                            <label htmlFor="contained-button-file">
                              <input
                                id="contained-button-file"
                                accept="image/*"
                                multiple
                                type="file"
                                className={classes.input}
                                onChange={event => {
                                  if (event.currentTarget.files) {
                                    setFieldValue(
                                      `image`,
                                      event?.currentTarget?.files[0],
                                    );
                                  }
                                }}
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                fullWidth
                              >
                                Carregar imagem
                              </Button>
                            </label>
                          </div>
                        </Grid>
                        <Grid item xs={8} lg={8}>
                          {value && value instanceof File && (
                            <Typography variant="body1">
                              {value.name}
                            </Typography>
                          )}
                        </Grid>
                      </>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default BannerForm;

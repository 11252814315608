import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Container,
  Paper,
  Grid,
  Typography,
  Switch,
} from '@material-ui/core';
import Swal from 'sweetalert2';

import Toast from '../../../components/Toast';

import { ICourseAlert } from '../../../common/interfaces/ICourseAlert';

import {
  postCourseAlert,
  getOneCourseAlert,
} from '../../../services/coursesApi/courseAlertService';

const CourseAlertForm: React.FC = () => {
  const { id: courseAlertId } = useParams<{ id?: string }>();
  const location = useLocation();
  const history = useHistory();

  const search = new URLSearchParams(location.search);
  const isEdit = search.get('edit') === 'true';

  const [courseAlert, setcourseAlert] = useState<ICourseAlert>({
    title: '',
    active: true,
    backgroundHexadecimal: '',
    textHexadecimal: '',
  });

  const createCourseAlert = async (data: ICourseAlert) => {
    const postData: ICourseAlert = {
      title: data.title,
      active: data.active,
      backgroundHexadecimal: data.backgroundHexadecimal,
      textHexadecimal: data.textHexadecimal,
    };

    await postCourseAlert(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Alerta de Certificado criado com Sucesso!',
        });
        history.push('/alertacursos');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível criar o alerta de certificado!',
        });
      });
  };

  const editCourseAlert = async (data: ICourseAlert) => {
    if (!courseAlertId) {
      return;
    }

    const postData: ICourseAlert = {
      id: courseAlertId,
      title: data.title,
      active: data.active,
      backgroundHexadecimal: data.backgroundHexadecimal,
      textHexadecimal: data.textHexadecimal,
    };

    await postCourseAlert(postData)
      .then(() => {
        Toast.fire({
          icon: 'success',
          title: 'Alerta de Certificado atualizado com Sucesso!',
        });
        history.push('/alertacursos');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi possível editar o alerta de certificado!',
        });
      });
  };

  const getCourseAlert = useCallback(async (id: string) => {
    Swal.fire({ title: 'Carregando os dados!!', html: 'Por favor aguarde!' });
    Swal.showLoading();
    await getOneCourseAlert(id)
      .then(response => {
        setcourseAlert(response.data);
        Swal.close();
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            error?.response?.data?.message ||
            'Não foi encontrar o alerta de certificado!',
        });
      });
  }, []);

  useEffect(() => {
    if (courseAlertId) {
      getCourseAlert(courseAlertId);
    }
  }, [getCourseAlert, courseAlertId]);

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: '10px' }}>
        <Typography variant="h4" style={{ paddingBottom: '20px' }}>
          Alertas de Cursos
        </Typography>
        <Formik
          initialValues={courseAlert}
          enableReinitialize
          validateOnChange
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);

            if (isEdit) {
              await editCourseAlert(data);
            } else {
              await createCourseAlert(data);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Field name="title">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Título"
                        disabled={!!courseAlertId && !isEdit}
                        placeholder="Título"
                        variant="outlined"
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="subtitle1">Ativo</Typography>
                  <Field name="active">
                    {({ field }: FieldProps) => (
                      <>
                        <Switch
                          {...field}
                          disabled={!!courseAlertId && !isEdit}
                          defaultValue={field.value}
                          color="primary"
                          checked={field.value}
                        />
                      </>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Field name="backgroundHexadecimal">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Cor de Fundo"
                        disabled={!!courseAlertId && !isEdit}
                        placeholder="Cor de Fundo"
                        variant="outlined"
                        multiline
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Field name="textHexadecimal">
                    {({ field, meta: { error, touched } }: FieldProps) => (
                      <TextField
                        {...field}
                        type="input"
                        fullWidth
                        label="Cor do Texto"
                        disabled={!!courseAlertId && !isEdit}
                        placeholder="Cor do Texto"
                        variant="outlined"
                        multiline
                        helperText={touched && error}
                        error={touched && !!error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default CourseAlertForm;

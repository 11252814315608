import { Paper } from '@material-ui/core';
import React from 'react';

import { Container } from './styles';

const AuthCard: React.FC = ({ children }) => {
  return (
    <Paper elevation={1}>
      <Container>{children}</Container>
    </Paper>
  );
};

export default AuthCard;
